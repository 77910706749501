import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, Renderer2, ViewChild } from '@angular/core';
import { OrderUser } from 'src/app/shared/Interfaces/pedido';
import { ScrollService } from '../../services/Scroll/scroll.service';

@Component({
  selector: 'app-order-footer',
  templateUrl: './order-footer.component.html',
  styleUrl: './order-footer.component.css'
})
export class OrderFooterComponent implements AfterViewInit{
  @ViewChild('offCanva') offCanva!: ElementRef
  @Input() sacola!: OrderUser
  @Input() deliveryFeeFree: boolean = false;
  @Input() subTotal!: number
  openInfo: boolean = false;

  offCanvasHeight: string | number = 'auto';
  initHeight: number = 0;
  private startY = 0;
  private isDragging = false;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    public scrollService: ScrollService
  ){}

  ngAfterViewInit(): void {

  }


  //Abre a informação da taxa
  openInfoFee(){
    this.offCanvasHeight = this.offCanva.nativeElement.offsetHeight;
    this.initHeight = Number(this.offCanvasHeight)
    this.openInfo = true;
    this.scrollService.disableScroll();
  }

  //Quando o usuario clica
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isDragging = true;
  }

  // Quando o usuario arrasta
  onTouchMove(event: TouchEvent) {
    if (!this.isDragging) return;

    const currentY = event.touches[0].clientY;
    const deltaY = currentY - this.startY;

    if (deltaY > 0) {
      this.offCanvasHeight = Math.max(0, this.initHeight - deltaY);
    }
  }

  // Finalizar o toque
  onTouchEnd() {
    this.isDragging = false;

    // Fechar se a altura for pequena
    if (Number(this.offCanvasHeight) < 50) {
      this.closeOffCanvas();
    } else {
      this.offCanvasHeight = this.initHeight; // Resetar altura
    }
  }

  // Fechar o OffCanvas
  closeOffCanvas() {
    this.openInfo = false;
    this.offCanvasHeight = this.initHeight

    setTimeout(() => {
      this.scrollService.enableScroll();
    }, 500);
  }
}
