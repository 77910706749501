import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopMenuComponent } from '../../components/top-menu/top-menu.component';
import { SplashComponent } from '../../components/splash/splash.component';
import { BottomMenuComponent } from '../../components/bottom-menu/bottom-menu.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/shared/modules/app/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { TopMenuTwoComponent } from '../../components/top-menu-two/top-menu-two.component';
import { BottomMenuConsumptionComponent } from '../../components/bottom-menu-consumption/bottom-menu-consumption.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FormChangeComponent } from '../../components/form-change/form-change.component';
import { ReceiptCardComponent } from '../../components/receipt-card/receipt-card.component';
import { CheckboxRadiusComponent } from '../../components/checkbox-radius/checkbox-radius.component';
import { MethodCardComponent } from '../../components/method-card/method-card.component';
import { PaymentFormCardComponent } from '../../components/payment-form-card/payment-form-card.component';
import { AddressCardComponent } from '../../components/address-card/address-card.component';
import { CardLoadingComponent } from '../../components/CardLoading/card-loading/card-loading.component';
import { CopyButtonComponent } from '../../components/copy-button/copy-button.component';
import { InputCopyPixComponent } from '../../components/input-copy-pix/input-copy-pix.component';
import { CounterTimingComponent } from '../../components/counter-timing/counter-timing.component';
import { CupomComponent } from '../../components/cupom/cupom.component';
import { ObservationCardComponent } from '../../components/observation-card/observation-card.component';
import { TimeAwaitComponent } from '../../components/time-await/time-await.component';
import { ModalPromotionComponent } from '../../components/modal-promotion/modal-promotion.component';
import { FormatationCurrencyDirective } from '../../directives/formatationCurrency.directive';
import { ButtonFidelityComponent } from '../../components/button-fidelity/button-fidelity.component';
import { ModalFidelityComponent } from '../../components/modal-fidelity/modal-fidelity.component';
import { InfoCompanyComponent } from '../../components/info-company/info-company.component';
import { OrderFooterComponent } from '../../components/order-footer/order-footer.component';
import { OrderDocumentComponent } from '../../components/order-document/order-document.component';
import { DirectiveModule } from '../directives/directive.module';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';

const components = [
  LayoutComponent,
  TopMenuComponent,
  BottomMenuComponent,
  SplashComponent,
  TopMenuTwoComponent,
  BottomMenuConsumptionComponent,
  FormChangeComponent,
  ReceiptCardComponent,
  CheckboxRadiusComponent,
  MethodCardComponent,
  PaymentFormCardComponent,
  AddressCardComponent,
  CardLoadingComponent,
  CopyButtonComponent,
  InputCopyPixComponent,
  CounterTimingComponent,
  CupomComponent,
  ObservationCardComponent,
  TimeAwaitComponent,
  ModalPromotionComponent,
  FormatationCurrencyDirective,
  ButtonFidelityComponent,
  ModalFidelityComponent,
  InfoCompanyComponent,
  OrderFooterComponent,
  OrderDocumentComponent
]

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgScrollbarModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    DirectiveModule
  ],
  exports: components
})
export class LayoutModule { }
