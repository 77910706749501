import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { ScrollService } from '../../services/Scroll/scroll.service';
import { Cliente } from 'src/app/shared/Interfaces/customer';
import { CacheService } from '../../services/Cache/cache.service';

@Component({
  selector: 'app-order-document',
  templateUrl: './order-document.component.html',
  styleUrl: './order-document.component.css'
})
export class OrderDocumentComponent {
  @ViewChild('offCanva') offCanva!: ElementRef
  @ViewChild('input') input!: ElementRef
  corretFormat!: boolean | undefined;
  inputValue: string = '';
  user!: Cliente;
  openInfo: boolean = false;
  company!: Company;
  offCanvasHeight: string | number = 'auto';
  initHeight: number = 0;
  private startY = 0;
  private isDragging = false;
  document: any = {
    document: '',
    formatted: ''
  };


  @Output()documentValidate : EventEmitter<string> = new EventEmitter<string>();






  constructor(private connection: ConnectionService, public scrollService: ScrollService, private cacheService: CacheService){
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })
    this.connection.user$.subscribe((User) =>{
      if(User){
        this.user = User
        if(this.user.document)
        {
          this.document = { document:  this.user.document }
          this.document.formatted = this.maskDoc(this.document.document)
          this.documentValidate.emit(this.document.document);
          this.corretFormat = true
        }

      }
      else if(this.cacheService.getItemLocal('user_key')){
        this.connection.getUser();
      }
    })
  }

    //Abre a informação da taxa
    openInfoDocument(){
      this.offCanvasHeight = this.offCanva.nativeElement.offsetHeight;
      this.initHeight = Number(this.offCanvasHeight)
      this.openInfo = true;
      this.inputValue = this.document.formatted
      this.scrollService.disableScroll();
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 200);
    }

    submitDocument(type: number){
      this.openInfo = false;
      this.scrollService.disableScroll();

      if(type == 1){
        if(this.inputValue == this.document.formatted)
        {
          this.document.formatted = this.maskDoc(this.document.document);
        }
        else{
          if(this.inputValue != this.document.document){
            this.document.document = this.inputValue;
            this.document.formatted = this.maskDoc(this.document.document);
          }
          else{
            this.document.formatted = this.maskDoc(this.document.document);
          }
        }

        this.documentValidate.emit(this.document.document);

        this.user.document = this.document.document
        this.connection.updateUser(this.user);
      }
      else{
        this.document.formatted = '';
        this.document.document = '';
        this.corretFormat = undefined;
        this.documentValidate.emit(this.document.document);
      }
      this.scrollService.enableScroll();

    }

    cancelEdit(){
      this.document.document = '';
      this.document.formatted = '';
      this.openInfo = false;
      this.corretFormat = undefined;
      this.scrollService.enableScroll();
    }

    maskDoc(document: string){
      const rawValue = document.replace(/\D/g, '');
      if (rawValue.length <= 11) {

        const one = rawValue.substring(0, 3);
        return `${one}.***.***-**`;

      } else {

        const one = rawValue.substring(0, 2);
        return `${one}.***.***/****-**`;
      }

    }

    //#region OffCanva

      //Quando o usuario clica
      onTouchStart(event: TouchEvent) {
        this.startY = event.touches[0].clientY;
        this.isDragging = true;
      }
    
      // Quando o usuario arrasta
      onTouchMove(event: TouchEvent) {
        if (!this.isDragging) return;
    
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - this.startY;
    
        if (deltaY > 0) {
          this.offCanvasHeight = Math.max(0, this.initHeight - deltaY);
        }
      }
    
      // Finalizar o toque
      onTouchEnd() {
        this.isDragging = false;
    
        // Fechar se a altura for pequena
        if (Number(this.offCanvasHeight) < 50) {
          this.closeOffCanvas();
        } else {
          this.offCanvasHeight = this.initHeight; // Resetar altura
        }
      }

      // Fechar o OffCanvas
      closeOffCanvas() {
        this.openInfo = false;
        this.offCanvasHeight = this.initHeight

        setTimeout(() => {
          this.scrollService.enableScroll();
        }, 500);
      }

    //#endregion


  //#region Formatação de documentos CPF - CNPJ

    //Recebe o evento para formatar o tipo do documento
    formatarDocumento(event: any) {
      const newValue = event.target.value;

      const rawValue = newValue.replace(/\D/g, '');
      let formatted = this.formatarCPF(rawValue);

      if(rawValue.length == 0){
        // this.AbilitButton()
        this.corretFormat = undefined
      }
      if(rawValue.length >= 1  && rawValue.length < 11)
      {
        // this.abilitButton = false;
        this.corretFormat = false
      }
      else if(rawValue.length == 11){
        this.corretFormat = true
        // this.AbilitButton()

      }
      else if(rawValue.length >= 12 && rawValue.length < 14){
        // this.abilitButton = false;
        this.corretFormat = false;
      }
      else if(rawValue.length == 14){
        this.corretFormat = true
        // this.AbilitButton()

      }

      if (rawValue.length <= 11) {
        formatted = this.formatarCPF(rawValue);
      } else {
        formatted = this.formatarCNPJ(rawValue);
      }


      // this.document.document = formatted
      // this.sacola.document_customer = this.document
      this.inputValue = formatted
    }

    //Formata o CPF
    formatarCPF(cpf: string): string {

      const areaCode = cpf.substring(0, 3);
      const firstPart = cpf.substring(3, 6);
      const secondPart = cpf.substring(6, 9);
      const treePart = cpf.substring(9, 11);
      if(cpf.length <= 2)
      {
        return `${areaCode}`;
      }
      else if(cpf.length >= 2 && cpf.length <= 5 )
      {
        return `${areaCode}.${firstPart}`
      }
      else if(cpf.length >= 5 && cpf.length <= 8 )
      {
        return `${areaCode}.${firstPart}.${secondPart}`
      }
      else
      {
        return `${areaCode}.${firstPart}.${secondPart}-${treePart}`
      }
    }

    //Formata o CNPJ
    formatarCNPJ(cnpj: string): string {

      const area1 = cnpj.substring(0, 2);
      const area2 = cnpj.substring(2, 5);
      const area3 = cnpj.substring(5, 8);
      const area4 = cnpj.substring(8, 12);
      const area5 = cnpj.substring(12, 14);

      if(cnpj.length <= 13)
      {
        return `${area1}.${area2}.${area3}/${area4}-`;
      }
      else{
        return `${area1}.${area2}.${area3}/${area4}-${area5}`;
      }
    }

  //#endregion
}
