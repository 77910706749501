import { Component, OnInit, AfterViewInit, ChangeDetectorRef, HostListener, Renderer2, ElementRef, Inject, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderUser, Pedido } from 'src/app/shared/Interfaces/pedido';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { OrderService } from 'src/app/shared/services/Order/order.service';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { UserService } from 'src/app/shared/services/User/user.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { DOCUMENT } from '@angular/common';
import { paymentForm, Pix } from 'src/app/shared/Interfaces/order';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Subscription } from 'rxjs';
import { paymentFormCompany, typeInterface } from 'src/app/shared/Interfaces/company';
import { Coupon } from 'src/app/shared/Interfaces/coupon';
import { Fidelity } from 'src/app/shared/Interfaces/fidelity';
import { SharedService } from 'src/app/shared/services/shared/shared.service';
import { Address, Cliente } from 'src/app/shared/Interfaces/customer';
import * as _ from 'lodash';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
export interface dataform{
  checked: boolean,
  value: number
}
export interface dataformAddress{
  checked: boolean,
  value: number,
  address: Address
}

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class OrderConfirmationComponent implements OnInit, AfterViewInit{

  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    openCardLoading = false;
    openTypePayment = false;
    openModalPix = false;
    openModal = false;
    showTopMenu = true;
    eventNavegation = false; 
    openLoadingComponent = false;
    abilitButton = false;
    deliveryFeeFree = false
    counterFinalizado = false;
    openModalObservation = false;
    observationActive = false;
    toastCloseStoreActive = false;
    metodoSelecionado = false;
    modalPromotion = false;
    disableButton = false;
    fidelityGet = false;
    openCouponView = false;
    desvinculationCoupon = false
    openModalFidelity = false;
    productsDiscount: Pedido[] = []
    couponSelected!: Coupon
    nameCompany: string = '';
    typeParam!: typeInterface;
    AdressEmpty!: Address;
    company!: Company;
    name!: string;
    phoneNumber!: string;
    // document: string = '';
    user!: Cliente;
    subTotal: number = 0;
    paymentObject: paymentForm[] = []
    flavorQuantity: number[] = []
    countText: number = 0;
    orderObservation: string = '';
    productPromotion: any;
    productInseringPromotion: any;
    productsOrigin: any;
    productsFidelity: any[] = [];
    fidelityUser!: Fidelity;
    methodAlteration: boolean = false;
    paymentFormEmpty!: paymentFormCompany;
    productsEmpty: Pedido[] = [];

    //Objeto do pix
    pixObject: Pix = {
      createdAt: '',
      copyPaste: '',
      qrCode: '',
      id_order: '',
      value: 0,
    }

    //Objeto do pedido
    sacola: OrderUser = {
      id_companyFK: 0,
      id_customerFK: '',
      document_customer: '',
      orderType: 0,
      orderPrice: 0,
      changeFor: '0',
      orderStatus: '',
      deliveryFee: 0,
      orderObservation: '',
      paymentForm: this.paymentObject,
      id_address: '',
      onlinePay: 0,
      products: this.productsEmpty,
      couponType: 0,
      id_coupon: '0',
      coupons: false,
      discountValue: 0
    };

    //Objeto de metodo de recebimento
    methodReceiptSelected = {
      checked: false,
      value: 0
    };

    //Objeto de endereço
    addressSelected: dataformAddress  = {
      checked: false,
      value: 0,
      address: this.AdressEmpty
    }

    //Objeto de Tipo de pagamento
    typeMethodSelected = {
      checked: false,
      value: 0
    }

    //Objeto de Formas de pagamento
    paymentFormSelected = {
      checked: false,
      value: 0,
      form: this.paymentFormEmpty
    }

  //#endregion

  //#region Inicializadores

    constructor(
      private cacheService: CacheService,
      private router: Router,
      private toastr: ToastrService,
      private orderService: OrderService,
      private cartService: CartService,
      private ChangeDetectorRef: ChangeDetectorRef,
      private layout: LayoutComponent,
      private connection: ConnectionService,
      private eventsService: EventsService,
      private userService: UserService,
      private apiService: ApiService,
      private sharedService: SharedService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private webHookService: WebhookService,
      @Inject(DOCUMENT) private Document: Document,
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })
        this.eventsService.eventNavegation$.subscribe((newEvent) =>{
          this.eventNavegation = newEvent
        })
        this.connection.typeParam$.subscribe((data) =>{
          this.typeParam = data;
        })
        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
        })
        this.connection.user$.subscribe((User) =>{
          if(User){
            this.user = User
          }
          else if(this.cacheService.getItemLocal('user_key')){
            this.connection.getUser();
          }
        })
        this.layout.checkScreenSize();
    }

    //Iniciado assim que o component é criado
    ngOnInit(): void {
      this.disablePullToRefresh()

      this.montarPedido();

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {
          if(content.type == 5){
            const data = content.data
            if(this.pixObject.id_order){
              if(data.id_order == this.pixObject.id_order)
                if(data.status == 1){
                  clearInterval(this.pixObject.id_interval)
                  this.toastr.success('Pagamento Confirmado!', 'Successo', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
                }
                else if(data.status == 6){
                  clearInterval(this.pixObject.id_interval)
                  this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
                  this.router.navigate([this.nameCompany, 'pedidos'])
                }
            }
          }
        },
        (error) => {
          console.error('Erro ao receber mensagem do servidor WebHook:', error);
        }
      );
    }

    //Inicializado após o termino do carregamento DOM
    ngAfterViewInit(){
      setTimeout(() => {
        this.name = this.user.name
        this.phoneNumber = this.user.phone
      });
    }

    //Chamada quando o component é destruido
    ngOnDestroy(): void {
      clearInterval(this.pixObject.id_interval);
      // this.eventsService.eventTypeReceipt = 0
    }

  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize')
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
    }

    //Função responsavel para não mostrar o modal caso tenha ido editar o usuario
    alterationEvent(){
      this.eventsService.eventNavegationSubject.next(true)
      this.userService.editingUserSubject.next(true);
      this.router.navigate([this.nameCompany, 'user-data'])
    }

    //O primeiro modal a ser aberto, quando o component inicia
    showModal(){
      setTimeout(() => {
        this.openModal = true
      }, 100);
      this.ChangeDetectorRef.detectChanges();
    }

    //Função que é chamada para fazer a verificação se todos campos estão requeridos para abilitar o botão de finalizar o pedido
    habilitButton() {
      const hasPayment = this.sacola.paymentForm.length !== 0;
      const isReceiptSelected = [3, 5].includes(this.methodReceiptSelected.value);
      const isAddressValid = this.methodReceiptSelected.value !== 5 || this.addressSelected.checked;
    
      this.abilitButton = isReceiptSelected && hasPayment && isAddressValid;
    }

    //Verifica o tipo de pagamento
    verificationOpentypePayment(data: { checked: boolean, value: number }){
      if(data.value == 5){
        this.openTypePayment = false;
        
      }
      else if(data.value == 3){
        this.openTypePayment = true;
      }
      else{
        this.openTypePayment = false;
      }
      // this.ChangeDetectorRef.detectChanges();
    }

    //Abre o modal de observação
    showModalObservation(){
      this.openModalObservation = true;
    }

    //Confirma a observação do pedido
    confirmObservation(){
      this.openModalObservation = false
      this.sacola.orderObservation = this.orderObservation
      if(this.sacola.orderObservation?.length != 0){
        this.observationActive = true;
      }
      else{
        this.observationActive = false;
      }
    }

    //Cancela a observação no pedido
    cancelObservation(){
      this.openModalObservation = false;
      this.orderObservation = this.sacola.orderObservation

      if(!this.observationActive){
        this.sacola.orderObservation = '';
      }
    }

    //atribui a observação do textArea na variavel
    valueTextArea(event: any){
      const inputElement = event?.target as HTMLInputElement;

      if(inputElement.value.length <= 200){
        this.orderObservation = inputElement.value
        this.countText = this.orderObservation.length;
      }
      else[
        inputElement.value = this.orderObservation
      ]

    }

    //Abre a view dos Cupons
    viewCoupon(event: any){
      if(event == true){
        this.openCouponView = true;
      }
      else{
        this.openCouponView = false;
        setTimeout(() => {
          window.scrollTo({
            behavior: 'instant' as ScrollBehavior,
            top: document.documentElement.scrollHeight,
            left: 0,
          });
        }, 10);

      }
    }

    documentValid(event: any){
      this.sacola.document_customer = event;
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //#region box selecteds

      goback(): void{
        if(this.company.company.status == 1){
          this.router.navigate([this.nameCompany, 'carrinho']);
          this.eventsService.eventNavegationSubject.next(false)
          this.eventsService.eventTypeReceiptSubject.next(0)
        }
        else{
          this.router.navigate([this.nameCompany]);
          this.eventsService.eventNavegationSubject.next(false)
          this.eventsService.eventTypeReceiptSubject.next(0)
        }

      }

      //Responsavel pelo metodo de recebimento do pedido
      methodSelected(data: { checked: boolean, value: number })
      {

        if(data.checked == false && data.value == 0)
        {
          const paymentEmpty = {checked: false, value: 0, form: this.paymentFormEmpty}
          this.removeFees();
          this.removeEdge();
          const dataAddress: dataformAddress = {checked: false, value: 0, address: this.AdressEmpty}
          this.addressSelected = dataAddress;
          this.typeMethodSelected = data;
          this.paymentFormSelected = paymentEmpty

          // limpa o Tipo de pagamento
          this.sacola.onlinePay = data.value

          // limpa a Forma de pagamento
          this.sacola.paymentForm = []
          this.paymentObject = []

          // limpa o endereço
          this.sacola.id_address = '';
          this.sacola.deliveryFee = 0;
          

          //Altera os produtos para o padrão inicial qualse tenha sido alterado
          if(this.sacola.coupons){
            if(this.sacola.couponType == 3){      
              this.sacola.orderPrice = this.calcTotalOrder();
              this.methodAlteration = true;
            }
            else{
              this.sacola.orderPrice = this.calcTotalOrder();
            }
          }
          else{
            this.sacola.orderPrice = this.calcTotalOrder();
          }

          
          if(this.sacola.couponType == 3 && this.sacola.coupons){
            this.sharedService.methodReceipt.next(true);
          }
        }
        
        //recebe o metodo de recebimento
        this.sacola.orderType = data.value

        this.methodReceiptSelected = data

        this.verificationOpentypePayment(data)

        this.habilitButton();
      }

      //Lógica para o endereço
      address(data: { checked: boolean, value: number, address: Address }){
        if(data.checked == false && data.value == 0 && data.address == undefined)
        {
          const dataEmpty = {checked: false, value: 0}
          const paymentEmpty = {checked: false, value: 0, form: this.paymentFormEmpty}

          this.removeFees();
          this.removeEdge();
          this.openTypePayment = false;
          this.typeMethodSelected = dataEmpty
          this.paymentFormSelected = paymentEmpty
          this.addressSelected = data

          // limpa o Tipo de pagamento
          this.sacola.onlinePay = data.value

          // limpa a Forma de pagamento
          this.sacola.paymentForm = []
          this.paymentObject = []


          //limpa o endereço
          this.sacola.id_address = '';
          this.sacola.deliveryFee = 0;


          if(this.sacola.coupons){
            if(this.sacola.couponType == 3){
              this.sacola.orderPrice = this.calcTotalOrder();
              this.methodAlteration = true;
            }
            else{
              this.sacola.orderPrice = this.calcTotalOrder();
            }
          }
          else{
            this.sacola.orderPrice = this.calcTotalOrder();
          }

          if(this.sacola.couponType == 3 && this.sacola.coupons){
            this.sharedService.methodReceipt.next(true);
          }


        }
        else{
          this.sacola.id_address = data.address.id_address;
          this.addressSelected = data

          if(typeof data.address.deliveryPrice == 'number')
          {
            if(this.sacola.deliveryFee == 0){

              this.sacola.deliveryFee = data.address.deliveryPrice;
              this.sacola.orderPrice += data.address.deliveryPrice;    
            }
          }
          if(data.address.deliveryPrice == 0)
          {
            this.deliveryFeeFree = true;
          }
    
          this.openTypePayment = true;
        }
      }

      //Tipo de pagamento do pedido
      typePayment(data: { checked: boolean, value: number })
      {

        this.typeMethodSelected = data;
        if(data.checked == false && data.value == 0)
        {
          const paymentEmpty = {checked: false, value: 0, form: this.paymentFormEmpty};
          
          this.metodoSelecionado = false;
          this.paymentFormSelected = paymentEmpty;
          // limpa a Forma de pagamento
          this.sacola.paymentForm = [];
          this.paymentObject = [];
          this.sacola.onlinePay = 0;
          this.removeFees();
          this.removeEdge();
        }

        this.metodoSelecionado = true
        this.habilitButton();
      }

      //Forma de pagamento do pedido
      formPayment(data: { checked: boolean, value: number, form: paymentFormCompany})
      {
        this.paymentFormSelected = data
        if(data.checked == false)
        {
          this.modalPromotion = false;
          this.sacola.paymentForm = []
          this.paymentObject = []
          this.sacola.onlinePay = 0;
          this.removeEdge();
          this.removeFees();
        }
        else{

          if(data.form.activeFee){
            this.addFees(data.form);
          }

          const form: paymentForm = {
            id_formpayment: data.form.id_paymentForm,
            changeFor: "0",
            onlinePay: 0,
            payStatus: 0,
            paymentName: this.paymentFormSelected.form.formName,
            paymentForm: this.paymentFormSelected.value,
            paymentValue: this.sacola.orderPrice.toString()
          }

          if(data.form.formNumber == 0){
            form.onlinePay = 1
            this.sacola.onlinePay = 1;
          }
          else{
            form.onlinePay = 0
            this.sacola.onlinePay = 0;
          }

          //adiciona ele no array e adiciona o array no pedido
          this.paymentObject.push(form)
          this.sacola.paymentForm = this.paymentObject

          if(this.company.configs.edgePix == 1){
            if(data.form.formNumber == 2 || data.form.formNumber == 0){
              this.promotionPayment(this.sacola)
            }
          }
        }
        this.habilitButton();
      }

      //Troco
      changeFor(event: any){
        if(typeof event == 'number')
        {
            this.sacola.changeFor = event.toString()
            this.sacola.paymentForm[0].changeFor = event.toString()
        }
      }

    //#endregion

    //#region Taxas

      //Adiciona as taxas ao pedido
      addFees(form: paymentFormCompany){

        const fee = {
          id: form.id_paymentForm,
          value: false,
          percent: false,
          type: form.typeFee,
          fee: 0,
          valueFee: 0
        }

        if(form.value){

          fee.value = true
          fee.fee = form.fee
          fee.valueFee = form.fee

          if(form.typeFee == 1){
            this.sacola.fee = fee
            // desconto
            this.sacola.orderPrice = this.sacola.orderPrice - fee.valueFee;
            this.verifiPrice();
          }
          else{      
            this.sacola.fee = fee
            // acrescimo
            this.sacola.orderPrice = this.sacola.orderPrice + fee.valueFee;
            this.verifiPrice();
          }
        }
        else{
          const percentValue = parseFloat(((this.subTotal * form.fee) / 100).toFixed(2));
          fee.percent = true
          fee.fee = form.fee
          fee.valueFee = percentValue

          if(form.typeFee == 1){
            this.sacola.fee = fee
            // desconto
            this.sacola.orderPrice = parseFloat((this.sacola.orderPrice - percentValue).toFixed(2));
            this.verifiPrice();
          }
          else{
            this.sacola.fee = fee
            // acrescimo
            this.sacola.orderPrice = parseFloat((this.sacola.orderPrice + percentValue).toFixed(2));
            this.verifiPrice();
          }
        }
      }

      //Remove as taxas
      removeFees(){
        if(this.sacola.fee){
          if(this.sacola.fee.type == 1){
            this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.fee.valueFee
          }
          else{
            this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.fee.valueFee
          }

          this.sacola.fee = undefined
        }

      }

      //Verifica se o preço não é abaixo de zero
      verifiPrice(){
        if(this.sacola.orderPrice < 0){
          this.sacola.orderPrice = 0;
        }
      }

    //#endregion 

    //#region Coupons

      //Quando o cupom selecionado é ativado
      coupomSelected(event: any){

        if(event.check){
          let coupon: Coupon = event.coupon
          if(coupon.couponType == 1 || coupon.couponType == 3){
            if(coupon.percent != 0)
              {
                const discontFormated = coupon.percent / 100;
                let discont;
                if(this.sacola.orderType == 5){
                  const orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee;
                  discont = orderPrice * discontFormated;
                  this.sacola.orderPrice = orderPrice - discont + this.sacola.deliveryFee;
                }
                else{
                  discont = this.sacola.orderPrice * discontFormated;
                  this.sacola.orderPrice = this.sacola.orderPrice - discont;
                }
                this.sacola.discountValue = discont;
                this.sacola.id_coupon = coupon.id_coupon;
                this.sacola.coupons = true;
                this.sacola.couponType = 1;
            }
            else if(coupon.value != 0){
              let discont = coupon.value
              this.sacola.orderPrice = this.sacola.orderPrice - discont
              this.sacola.discountValue = discont;
              this.sacola.id_coupon = coupon.id_coupon
              this.sacola.coupons = true,
              this.sacola.couponType = 2
            }
            else if(coupon.deliveryFree == true){
              const desconto = this.sacola.deliveryFee
              this.sacola.discountValue = desconto
              this.sacola.id_coupon = coupon.id_coupon,
              this.sacola.coupons = true
              this.deliveryFeeFree = true
              this.sacola.couponType = 3
              this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee
              this.sacola.deliveryFee = 0
            }
          }
          else if(coupon.couponType == 2){
            this.productsDiscount = this.sacola.products.filter(prodOrder => coupon.discountProducts.includes(prodOrder.id_product.toString())).map(prodOrder => ({ ...prodOrder }));
          
            if (this.productsDiscount.length === 1) {
              this.couponSelected = coupon
              this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
            } else if (this.productsDiscount.length > 1) {
              if(coupon.percent != 0){
                this.openModalFidelity = true;
                this.couponSelected = coupon;
              }
              else{
                this.couponSelected = coupon
                this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
              }

            } 
          }
        }
        else{
          this.deslectedCupom();
        }
      }

      //Aplica o desconto de cupons do tipo produto 
      applyCouponDiscount(event: any) {
        const product = event.product;
        const coupon = event.coupon;
        const matchingItem = this.sacola.products.filter(productOrder => this.equalsObjectsCompare(product, productOrder, ["id_product", "observations"]));

        if(matchingItem){

          const discount = this.calculateDiscount(matchingItem[0], coupon);
          this.applyDiscountToProduct(matchingItem[0], discount);
  
          this.sacola.discountValue = discount;
          this.sacola.id_coupon = coupon.id_coupon;
          this.sacola.coupons = true;
          this.sacola.couponType = 2;
          this.sacola.orderPrice = this.sacola.orderPrice - discount
          this.toastr.clear();
          this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
        }

        this.openModalFidelity = false;
      }

      //Aplica o disconto no produto
      private applyDiscountToProduct(matchingItem: any, discount: number) {
        if (matchingItem.quantity > 1) {
          //Novo Item
          const productDiscount = JSON.parse(JSON.stringify(matchingItem));
          productDiscount.totalPrice = matchingItem.totalPrice / matchingItem.quantity;
          productDiscount.oldPrice = productDiscount.totalPrice;
          productDiscount.discountValue = discount;
          productDiscount.totalPrice -= discount;
          productDiscount.discount = true;
          productDiscount.quantity = 1;

          if (productDiscount.flavorsValue) {
            productDiscount.flavorsValue = productDiscount.baseFlavorsValue;
          }   
          const priceDiscount = matchingItem.totalPrice / matchingItem.quantity;

          this.sacola.products.push(productDiscount);

          //Item Antigo
          if (matchingItem.flavorsValue) {
            matchingItem.flavorsValue -= matchingItem.baseFlavorsValue;
          }
          matchingItem.totalPrice = priceDiscount * (matchingItem.quantity - 1);
          matchingItem.quantity -= 1;
        } else {
          matchingItem.oldPrice = matchingItem.totalPrice;
          matchingItem.totalPrice -= discount;
          matchingItem.discount = true;
          matchingItem.discountValue = discount;

          if (matchingItem.flavorsValue) {
            matchingItem.flavorsValue -= matchingItem.baseFlavorsValue;
          }
        }

        let count = 0
        this.sacola.products.forEach((product, index) =>{
          count = 0
          product.categories.forEach(categorie =>{
            let value = 0
            categorie.additional.forEach(additional =>{
              value += additional.quantitySub
              if(categorie.flavorCategory == 1)
              {
                count += additional.quantitySub
              }
            })
          })
          this.flavorQuantity.push(count)
        })
      }

      //Calcula quanto de desconto o produto recebera
      private calculateDiscount(matchingItem: any, coupon: any): number {
        if (coupon.percent != 0) {
          if(matchingItem.quantity > 1){
            const totalPrice = matchingItem.totalPrice / matchingItem.quantity
            return totalPrice * (coupon.percent / 100);
          }
          else{
            return matchingItem.totalPrice * (coupon.percent / 100);
          }

        } else if (coupon.value != 0) {
          return coupon.value;
        }
        return 0;
      }

      //Quando o usuario cancela a seleção de produtos que ganharão o desconto
      cancelSelectCoupon(){
        this.desvinculationCoupon = true;
        this.openModalFidelity = false
        setTimeout(() => {
          this.desvinculationCoupon = false;
        }, 100);
      }

      //Desseleciona o cupom e retira suas propriedades no pedido
      deslectedCupom(){
        let coupon!: Coupon;
        this.couponSelected = coupon

        this.productsDiscount = []

        if(this.sacola.discountValue)
        {
          if(this.sacola.couponType == 3 && !this.methodAlteration){
            this.sacola.deliveryFee = this.sacola.discountValue;
          }

          if(!this.methodAlteration){
            this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.discountValue;
          }

          this.deliveryFeeFree = false;
          
          this.sacola.id_coupon = '0';
          this.sacola.coupons = false;
          this.sacola.discountValue = 0;
          this.sacola.couponType = 0;
          this.methodAlteration = false;
        }

        this.sacola.products.forEach(product => {
          if(product.discount){
            product.discount = false,
            product.discountValue = 0,
            product.totalPrice = product.oldPrice
            product.oldPrice = 0
            this.joinDuplications(this.sacola.products)
          }
        });
      }

    //#endregion

    //#region Montagem e finalização do pedido

      //Resgata a fidelidade do usuario caso não tenha sido carregada
      // getFidelity(){
      //   if(this.company){
      //     if(this.user){
      //       if(!this.fidelityGet)
      //       {
      //         this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
      //           (data) =>{
      //             console.log(data)
      //             this.fidelityUser = data;
      //             this.fidelityGet = true;

      //           },
      //           (error) =>{
      //             console.log(error);
      //           }
      //         )
      //       }
      //     }
      //   }

      // }

      

      //Monta o objeto de pedido de usuario, tanto para Pedido em balcão quanto em delivery
      montarPedido(){
        this.sacola = {
          id_customerFK: this.user.id_customer,
          document_customer: '',
          id_companyFK: this.company.company.id_company,
          orderObservation: '',
          products: JSON.parse(JSON.stringify(this.orderService.bag)),
          changeFor: '0',
          orderType: 0,
          orderStatus: '1',
          deliveryFee: 0,
          paymentForm: this.paymentObject,
          onlinePay: 0,
          orderPrice: 0,
          id_coupon: '0',
          discountValue: 0,
          coupons: false,
          couponType: 0
        }

        this.sacola.orderPrice = this.calcTotalOrder();

        let count = 0;

        this.sacola.products.forEach((product, index) =>{
          count = 0
          product.categories.forEach(categorie =>{
            let value = 0
            categorie.additional.forEach(additional =>{
              value += additional.quantitySub
              if(categorie.flavorCategory == 1)
              {
                count += additional.quantitySub
              }
            })
          })
          this.flavorQuantity.push(count)
        })
      }

      //Manda o pedido para o serviço e limpa os itens do carrinho
      finalizationOrder(){
        this.disableButton = true;

        this.apiService.verifiStatusCompany(this.company.company.id_company).subscribe(
          (data) =>{
            if(data[0].open){
              this.eventsService.eventNavegationSubject.next(false)
              this.eventsService.eventTypeReceiptSubject.next(0)
        
              if(this.sacola.onlinePay == 1){
                this.paymentPix(this.sacola);
              }
              else{
                this.orderService.OrderUser(this.sacola);
                this.router.navigate([this.nameCompany, 'processing']);
              }
        
              this.cartService.clearCart();
            }
            else{
              if(!this.toastCloseStoreActive)
              {
                this.toastCloseStoreActive = true;
                this.toastr.error('Desculpe, mas a loja já fechou!', 'Loja Fechada',
                { positionClass: 'toast-top-right', timeOut: 0, tapToDismiss: true,}
                ).onHidden.subscribe(() =>{ this.toastCloseStoreActive = false});
              }

              this.connection.Empresa(this.nameCompany, this.typeParam.type).subscribe(
                (data) =>{
                  console.log('Status da loja atualizado');
                },
                (error) =>{
                  console.log('Erro inesperado', error)
                }
              )

            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }  

      //Calcula o total da ordem para ser montado o objeto e feito o request
      calcTotalOrder(){
        let total = 0
        let subTotal = 0
        this.sacola.products.forEach(product => {
          if(product.discount){
            subTotal += product.totalPrice + product.discountValue
          }
          else{
            subTotal += product.totalPrice
          }
          total += product.totalPrice
        });

        this.subTotal = subTotal

        return total
      }

    //#endregion

    //#region Promoção para pagamento Pix (Borda Pix)

      //Detecta, se existe promoção para aqueles produtos
      promotionPayment(products: OrderUser){
        this.apiService.promotionPaymentForm(products).subscribe(
          (data) =>{
            if(!data.empty){
              this.productInseringPromotion = data.principalProducts;
              this.productPromotion = data.promoProducts[0];
              this.modalPromotion = true;
            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Faz a lógica de localizar e adicionar a promoção no produto
      promotionSelected(data:{productOrder: Pedido, productPromotion: any}){
        const product = this.sacola.products.filter(prod => this.equalsObjectsCompare(data.productOrder, prod, ["id_product", "observations", "discount"]))[0];

        if(product){
          if(product.quantity > 1){

            const newProduct = JSON.parse(JSON.stringify(product));
            newProduct.quantity = 1;
            newProduct.totalPrice = newProduct.baseValue;       
            if (newProduct.flavorsValue) {
              newProduct.flavorsValue = newProduct.baseFlavorsValue;
            }

            product.quantity = product.quantity - 1;
            product.totalPrice -= newProduct.baseValue;
            if (product.flavorsValue) {
              product.flavorsValue -= newProduct.baseFlavorsValue;
            }

            this.sacola.products.push(newProduct);
            data.productPromotion.promotionPay = true;
            newProduct.categories.push(data.productPromotion);

            let count = 0
            this.sacola.products.forEach((product, index) =>{
              count = 0
              product.categories.forEach(categorie =>{
                let value = 0
                categorie.additional.forEach(additional =>{
                  value += additional.quantitySub
                  if(categorie.flavorCategory == 1)
                  {
                    count += additional.quantitySub
                  }
                })
              })
              this.flavorQuantity.push(count)
            })

          }
          else{
            data.productPromotion.promotionPay = true;
            product.categories.push(data.productPromotion)
          }
          console.log('Borda adicionadas',this.sacola.products);
          this.modalPromotion = false;
        }
        else{
          this.modalPromotion = false;
        }
      }

      //Remove a borda Pix gratis
      removeEdge(){
        this.sacola.products.forEach(products =>{
          const indexCat = products.categories.findIndex(categorie => categorie.promotionPay)
          if(indexCat != -1){
            products.categories.splice(indexCat, 1)
          }
        })
        this.joinDuplications(this.sacola.products);
      }

    //#endregion 

    //#region Pix

      //Quando o usuario seleciona o pix, função responsavel de montalo
      paymentPix(sacola: OrderUser){
        this.openCardLoading = true;
        this.orderService.OrderUserPaymentPix(sacola).subscribe(
          (data) =>{
            this.apiService.paymentPix(data).subscribe((dataPayment) =>{
              this.openModalPix = true;
              this.openCardLoading = false;

              this.pixObject = {
                id_order: data.id_order,
                createdAt: data.createdAt,
                copyPaste: dataPayment.pagamento,
                qrCode: 'data:image/png;base64,' + dataPayment.pagamentoQrCode,
                value: dataPayment.valorTotal,
              }
              this.startVerification(data.id_order, this.company.company.id_company);
            },
            (error) =>{
              console.log(error)
              this.toastr.error('Erro ao solicitar pagamento', 'Erro', { positionClass: 'toast-top-right', timeOut: 4000});
              this.openCardLoading = false;
            })
          },(error) =>{
            console.log(error)
            this.openCardLoading = false;

          });
      }

      //Envia uma requisição para o servidor webhook para fazer a verificação
      startVerification(id_order: string, id_company: number){
        this.apiService.startVerification(id_order, id_company).subscribe(
          () =>{
            console.log('O Servidor iniciou a verificação')
            this.pixObject.id_interval = setInterval(() => {
              this.getStatusOrder(id_order)
            }, 10000)
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Resgata a confirmação de pagamento pix
      getStatusOrder(id_order: string){
        this.apiService.getStatusOrder(id_order).subscribe(
          (data) =>{
            if(data.status == 1 || data.status == 2){
              clearInterval(this.pixObject.id_interval)
              this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order]);
            }
            else if(data.status == 10){
              clearInterval(this.pixObject.id_interval)
              this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'pedidos']);
            }
          },
          (error) =>{

          }
        )
      }

      //Fecha o contador quando o tempo termina
      finalizationCounter(event: any){
        this.counterFinalizado = event
      }

      //Volta para view de pedidos
      backOrders(){
        this.router.navigate([this.nameCompany, 'pedidos']);
      }
    //#endregion

    //#region Comparação Produtos | Duplicações

      // Comparando APENAS as propriedades específicas dos produtos / categorias / adicionais
      equalsObjectsCompare(obj1: any, obj2: any, keysToCompare: any): boolean {
        if (!obj1 || !obj2) return false;
      
        // Comparando APENAS as propriedades específicas dos Produtos
        if (!_.isEqual(_.pick(obj1, keysToCompare), _.pick(obj2, keysToCompare))) {
          return false;
        }
      
        // Comparando categorias
        const categories1 = obj1.categories?.map((categorie: any) => this.extractCategoryData(categorie)) || [];
        const categories2 = obj2.categories?.map((categorie: any) => this.extractCategoryData(categorie)) || [];
      
        return _.isEqual(categories1, categories2);
      }
      
      //Extrai a comparação dos adicionais e monta o objeto de comparação da categoria
      extractCategoryData(category: any): object {
        return {
          id_category: category.id_category,
          additional: category.additional?.map((additional: any) => this.extractAdditionalData(additional)) || []
        };
      }
      
      //Compara APENAS as propriedades específicas dos Adicionais
      extractAdditionalData(additional: any): object {
        return _.pick(additional, ["add_id_product", "quantitySub"]);
      }

      //Junta as Duplicações que foram salvas
      joinDuplications(products: Pedido[]){
        for (let i = 0; i < products.length; i++) {
          const product = products[i];
          const duplicates = products.filter((prod, index) => prod.id_product === product.id_product && index > i);
      
          duplicates.forEach(duplicate => {
            if (this.equalsObjectsCompare(product, duplicate, ["id_product", "observations", "discount"])) {
              console.log('Esses dois aqui, são iguais:', [product, duplicate]);
      
              // Atualiza a quantidade e o preço total
              product.quantity += duplicate.quantity;
              product.totalPrice = product.unitPrice * product.quantity;
      
              // Remove o item duplicado
              const indexToRemove = products.indexOf(duplicate);
              if (indexToRemove > -1) {
                products.splice(indexToRemove, 1);
              }
            }
          });
        }
      }

    //#endregion

  //#endregion

}
