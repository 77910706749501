import { Component, Input, OnInit } from '@angular/core';
import { WebhookService } from '../../services/WeebHook/webhook.service';
import { Subscription } from 'rxjs';
import { Events, Order } from 'src/app/shared/Interfaces/order';

@Component({
  selector: 'app-history-events',
  templateUrl: './history-events.component.html',
  styleUrl: './history-events.component.css'
})
export class HistoryEventsComponent implements OnInit {

  @Input() order!: Order
  collapse = false
  lastEvent: Events = {
    eventType: '',
    createdAt: '',
    eventObservation: '',
    orderStatus: 0
  };
  private webHookSubscription!: Subscription;

  constructor(private webHookService: WebhookService){}

  ngOnInit(): void {
    // console.log(this.order)
    if(this.order.events.length != 0){
      this.lastEvent = this.order.events[this.order.events.length - 1]
      this.order.events.reverse()
    }
    else{
      this.lastEvent.createdAt = this.order.createdAt
      this.lastEvent.orderStatus = this.order.orderStatus
    }


    this.webHookSubscription = this.webHookService.getUpdates().subscribe(
      (content: any) => {
        if(content.type == 1){
          const data = content.data
          if(this.order.id_order == data.company.id_order){
            this.lastEvent = data.webhooks[data.webhooks.length - 1]
          }
        }
      },
      (error) => {
        console.error('Erro ao receber mensagem do servidor WebHook:', error);
      }
    );
  }

  ngOnDestroy(): void {
    this.webHookSubscription.unsubscribe;
  }

  toggleCollapse():void{
    if(this.order.events.length > 1){
      this.collapse = !this.collapse;
    }

  }
}
