import { Component, OnInit, HostListener, AfterViewInit, Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inicialization } from './shared/Interfaces/company';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})


export class AppComponent implements OnInit, AfterViewInit {
  title = 'Pedeaqui'
  empresa!: Inicialization;

  constructor(@Inject(DOCUMENT) private document: Document ){}

  ngOnInit(): void {
    this.verifiSize();
  }
  ngAfterViewInit(): void {
  }

  @HostListener('window:resize', [])
  onWindowResize(){
    this.verifiSize();
  }

  //Faz uma verificação assim que a aplicação incia para preparar a responsividade
  verifiSize(){
    if(window.innerWidth <= 470)
    {
      this.document.body.classList.add('mobile')
      this.document.body.classList.remove('tablet')
      this.document.body.classList.remove('desktop')
    }
    else if(window.innerWidth > 470 && window.innerWidth < 847){
      this.document.body.classList.add('tablet')
      this.document.body.classList.remove('mobile')
      this.document.body.classList.remove('desktop')
    }
    else if(window.innerWidth >= 847){
      this.document.body.classList.add('desktop')
      this.document.body.classList.remove('mobile')
      this.document.body.classList.remove('tablet')
    }
  }













}
