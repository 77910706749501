import { AfterViewInit, Component, ElementRef, HostListener, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { ConnectionService} from 'src/app/shared/services/Connection/connection.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
@Injectable({
  providedIn: 'root'
})
export class LayoutComponent implements OnInit, AfterViewInit {

//#region Variaveis de elemento HTML
  @ViewChild('mainContainer') mainContainer!: ElementRef;
//#endregion

//#region Variaveis Locais
  gif = false;
  stopLoading = false;
//#endregion

//#region Inicializadores
constructor(private router: Router, private connection: ConnectionService, private cacheService: CacheService, @Inject(DOCUMENT) private document: Document)
{
  this.connection.StopLoading$.subscribe((newValue) =>{
    this.stopLoading = newValue
  })
}

ngOnInit(): void {
  this.cacheService.setItemLocal('dark-theme', false);
}

ngAfterViewInit(): void {
  this.checkScreenSize();

  if(this.cacheService.getItemLocal('dark-theme')){
    this.addClassDark();
  }

}

//#endregion

//#region Funções de estilo (Style)

    //Adiciona a classe theme-dark ao body
    addclass() {
      const body = this.document.body;
      body.classList.toggle('theme-dark');
    }

    addClassDark(){
      const body = this.document.body;
      body.classList.add('theme-dark');
    }

    //captura a alteração no size da tela
    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Captura o size da tela e adiciona ou remove class dos elementos de acordo com o tamanhoda tela
    checkScreenSize(){
      if(window.innerWidth <= 1168)
      {
        if(this.mainContainer)
        {
          //main
          this.mainContainer.nativeElement.classList.add('responsive')
          this.mainContainer.nativeElement.classList.remove('large')
        }
      }
      else{

        //main
        if
        (
          this.router.url.includes('user-data') || this.router.url.includes('feedback')
          || this.router.url.includes('carrinho') || this.router.url.includes('consumption')
          || this.router.url.includes('pedidos') || this.router.url.includes('order-confirmation')
          || this.router.url.includes('order-details') || this.router.url.includes('edit-product')
          || this.router.url.includes('address') || this.router.url.includes('user')
          || this.router.url.includes('coupons') || this.router.url.includes('fidelity')
        )
        {
          this.mainContainer.nativeElement.classList.add('responsive')
          this.mainContainer.nativeElement.classList.remove('large')
        }
        else{
          if(this.mainContainer){
            this.mainContainer.nativeElement.classList.remove('responsive')
          }
        }

      }
    }

  //#endregion
}
