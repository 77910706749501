import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { CacheService } from '../../shared/services/Cache/cache.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Order } from 'src/app/shared/Interfaces/order';
import { typeInterface } from 'src/app/shared/Interfaces/company';
import { Cliente } from 'src/app/shared/Interfaces/customer';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css'],
})
export class PedidosComponent implements OnInit {

  webhookMessages: any[] = [];
  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    private typeParam!: typeInterface;
    quantityOrders: number = 3;
    nameCompany: string ='';
    id_empresa!: number;
    company!: Company
    orders!: Order[]
    requestSucess = false;
    user!: Cliente;
    showTopMenu = true;
    totalOrders: number | undefined = 0;
    theLastOrder = false;
    intervalId: any[] = [];
    idsOrders: Order[] = []
  //#endregion

  //#region Inicializadores
    constructor(
      private cacheService: CacheService,
      private router: Router,
      private connection: ConnectionService,
      private apiService: ApiService,
      private layout: LayoutComponent,
      private location: Location,
      private webHookService: WebhookService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private toastr: ToastrService,
      ){

        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany
        })
        //Resgata o nome da empresa e o parametro se é mesa
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam
        })
        this.layout.checkScreenSize();
        this.checkScreenSize();
    }

    ngOnInit(){
      // this.solicitarPermissao()
      // this.enviarNotificacao()
      this.disablePullToRefresh()

      this.webHookSubscription = this.webHookService.getUpdates().subscribe(
        (content: any) => {
          if(content.type == 1){
            const data = content.data
              this.orders.forEach(order => {
                if(order.id_order == data.company.id_order){
                  order.orderStatus = data.company.orderStatus
                  order.events = data.webhooks
                }
              });
          }
          else if(content.type == 5){
            const data = content.data

            const order = this.idsOrders.find(order => order.id_order == data.id_order)
            const index = this.idsOrders.findIndex(order => order.id_order == data.id_order)
              if(order)
              {
                if(data.status == 1){
                  clearInterval(this.intervalId[index]);
                  this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
                  order.orderStatus = 1;
                }
                else if(data.status == 6){
                  clearInterval(this.intervalId[index]);
                  this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
                  order.orderStatus = 10;
                }
              }

  
          }
        },
        (error) => {
          console.error('Erro ao receber mensagem do servidor WebHook:', error);
        }
      );


      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        this.id_empresa = this.company.company.id_company;
      })
      this.user = this.cacheService.getItemLocal('user_key')
      this.getOrders()
    }

  //#endregion
  ngOnDestroy(): void {
    if(this.webHookSubscription) {
      this.webHookSubscription.unsubscribe();
    }
    this.intervalId.forEach(interval => {
      clearInterval(interval);
      // console.log(interval, 'Finalizado')
    });
    
  }

  //#region Funções de Estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }


    getProgressBarWidth(order: Order): number {
    const status = order.orderStatus || 1; // Certifique-se de que há um status definido
    return (Number(status) - 1) * 25;
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
      this.layout.checkScreenSize();
    }

  //#endregion

  //#region Funções de Lógica (Logic)
    goback(): void{
      if(this.typeParam.type == 3)
      {
        this.router.navigate([this.nameCompany, 'B']);
      }
      else{
        this.router.navigate([this.nameCompany]);
      }
    }

  //Resgata todos pedidos que o usuario ja fez naquele estabelecimento
    getOrders(){
      this.apiService.getOrdersUser(this.user.id_customer, this.id_empresa, this.quantityOrders).subscribe((response) => {
        this.requestSucess = true
        this.idsOrders = []
        this.intervalId.forEach(interval => {
          clearInterval(interval);
          console.log(interval, 'Finalizado')
        });
        this.intervalId = []
        if(response.response.info)
        {
          this.orders = response.response.info[0].orders
          this.totalOrders = response.response.info[0].orders[0].customer.totalOrders
          if(this.totalOrders){
            if(this.orders.length < this.totalOrders){
              this.theLastOrder = false;
            }
            else{
              this.theLastOrder = true;
            }
          }

          this.orders.forEach(order => {
            if(order.orderStatus == 9)
            {
              this.idsOrders.push(order);
            }
          });
          this.getStatusOrders();
        }
      },
      (error) =>{
        this.requestSucess = false;
      }
      )
    }

    getStatusOrders(){
      this.idsOrders.forEach((order, index) => {
        this.intervalId.push(
          setInterval(() =>{
            this.apiService.getStatusOrder(order.id_order.toString()).subscribe(
              (data) =>{
                if(data.status == 1){
                  this.toastr.success('Pagamento Confirmado!', 'Success!', { positionClass: 'toast-top-right', timeOut: 4000});
                  order.orderStatus = 1;
                  clearInterval(this.intervalId[index])
                }
                else if(data.status == 10){
                  clearInterval(this.intervalId[index])
                  order.orderStatus = 10
                  this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado!', { positionClass: 'toast-top-right', timeOut: 4000});
                }
              },
              (error) =>{console.log(error);}
            )
          }, 10000)
        )
      });
    }

    subquantityOrders(){
      this.quantityOrders += 2;
      this.requestSucess = false;
      setTimeout(() => {
        this.getOrders();
      }, 800);

    }

    orderDetail(id: number)
    {
      this.router.navigate(['/', this.nameCompany, 'order-details', id]);
    }

  //#endregion


}
