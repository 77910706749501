import { Injectable } from '@angular/core';
import { OrderLocal, OrderUser, Pedido} from 'src/app/shared/Interfaces/pedido';
import { ApiService } from '../API/api.service';
import { Produto } from 'src/app/shared/Interfaces/produtos';
import { Router } from '@angular/router';
import { CacheService } from '../Cache/cache.service';
import { Company, ConnectionService } from '../Connection/connection.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, retry } from 'rxjs';
import { CartService } from '../Cart/cart.service';
import { OrderConfirmationComponent } from 'src/app/views/order-confirmation/order-confirmation.component';
import { typeInterface } from 'src/app/shared/Interfaces/company';
import { Cliente } from 'src/app/shared/Interfaces/customer';
import { PixelService } from '../pixel.service';




@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private nameCompany: string = '';
  private typeParam!: typeInterface;
  // private counterParam: number = 0;
  private sacola!: OrderLocal;
  private company!: Company;
  private user!: Cliente;

  table: number = 0;
  bag: Pedido[] = []

  counterOrderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public counterOrder$: Observable<number> = this.counterOrderSubject.asObservable();

  idOrderSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public idOrder$: Observable<string> = this.idOrderSubject.asObservable();

  closeCardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public closeCard$: Observable<boolean> = this.closeCardSubject.asObservable();

  constructor(
    private location: Location,
    private cartService: CartService,
    private apiService: ApiService,
    private router: Router,
    private cacheService: CacheService,
    private connection: ConnectionService,
    private toastr: ToastrService,
    private pixelService: PixelService
  )
  {

    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })
    //Resgata o nome da empresa e o parametro se é mesa
    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })
    this.connection.user$.subscribe((newUser) => {
      if(newUser){
        this.user = newUser
      }
    })
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })

  }

  keepBag(bag: Pedido[]){
    this.bag = bag;
  }

  editItemBag(item: Pedido, index: number){
    this.bag[index] = item
  }


  //Pedido efetuado Localmente
  OrderTableCard(bag: OrderLocal){
    this.apiService.MakeOrderLocal(bag).subscribe(
      (response) => {
        this.counterOrderSubject.next(bag.products.length)
        console.log(`Pedido da mesa/comanda feito com sucesso`);
        this.toastr.success('Pedido Feito com sucesso.', 'Successo', { positionClass: 'toast-top-right', timeOut: 1500});
        this.cacheService.removeItemLocal("product-cart")
        this.router.navigate([this.nameCompany, 'P']);
        this.cacheService.setItemLocal('session_id', response.response.info[0].orders[0].id_order)
      },
      (error) => {
        console.error('Falha ao fazer pedido:', error);
        this.toastr.error('Falha ao fazer o pedido.', 'Error', { positionClass: 'toast-top-right', timeOut: 3000});
      }
    )
  }

  //Pedido efetuado mediante a Pix
  OrderUserPaymentPix(bag: OrderUser):Observable<any>{

    return new Observable(observer => {

      this.apiService.FazerPedido(bag).subscribe(
        (response) => {
          if(this.typeParam.type == 0 || this.typeParam.type == 3){
            console.log('Pedido de usuario Feito com sucesso:', response);
            this.cacheService.removeItemLocal("product-cart")
          }
          else{
            console.log('Pedido de usuario Feito com sucesso:', response);
            this.cacheService.removeItemLocal("product-cart")
          }
        // this.sendEvent(response.response.info[0].orders[0]);
        observer.next(response.response.info[0].orders[0]);
        observer.complete();

        },
        (error) => {
          if(this.typeParam.type == 3){
            setTimeout(() => {
              this.location.replaceState(`${this.nameCompany}/B`);
              this.router.navigate([`${this.nameCompany}/B`]);
            }, 8000);
          }
          else{
            setTimeout(() => {
              this.router.navigate([this.nameCompany]);
            }, 8000);
          }

          observer.next(false);
          observer.complete();
        }
      )
    });
  }


  //Pedido efeturado Delivery ou Balcão
  OrderUser(bag: OrderUser){
      this.apiService.FazerPedido(bag).subscribe(
        (response) => {
          if(this.typeParam.type == 3){
            console.log('Pedido de usuario Feito com sucesso');
            this.cacheService.removeItemLocal("product-cart")
            this.connection.getFidelity(this.user.id_customer, this.company.company.id_company);
            this.sendEvent(response.response.info[0].orders[0]);

            setTimeout(() => {
              this.location.replaceState(`${this.nameCompany}/B`);
              this.router.navigate([this.nameCompany, 'pedidos']);
              this.toastr.success('Pedido Feito com sucesso', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 1500});
            }, 8000);

          }
          else{
            console.log('Pedido de usuario Feito com sucesso');
            this.cacheService.removeItemLocal("product-cart");
            this.connection.getFidelity(this.user.id_customer, this.company.company.id_company);
            this.sendEvent(response.response.info[0].orders[0]);

            setTimeout(() => {
              this.location.replaceState(this.nameCompany);
              this.router.navigate([this.nameCompany, 'pedidos']);
              this.toastr.success('Pedido Feito com sucesso', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 1500});
            }, 8000);

          }
        },
        (error) => {
          if(this.typeParam.type == 3){
            setTimeout(() => {
              this.location.replaceState(`${this.nameCompany}/B`);
              this.router.navigate([`${this.nameCompany}/B`]);
              this.toastr.error('Falha ao fazer o pedido.', 'Error', { positionClass: 'toast-top-right', timeOut: 3000});
            }, 8000);
          }
          else{
            setTimeout(() => {
              this.router.navigate([this.nameCompany]);
              this.toastr.error('Falha ao fazer o pedido.', 'Error', { positionClass: 'toast-top-right', timeOut: 3000});
            }, 8000);
          }
        }
      )
  }

  //Emite o evento de pedido gerado para o Pixel(Facebook)
  sendEvent(order: any){
    this.pixelService.trackPurchase({
      action_source: 'website',
      event_id: Math.floor(Math.random() * 100000),
      event_time: Math.floor(Date.now() / 1000),
      user_data: {
        client_user_agent: navigator.userAgent,
        em: 'f660ab912ec121d1b1e928a0bb4bc61b15f5ad44d5efdc4e1c92a25e99b8e44a',
      },
      currency: 'BRL',
      value: parseFloat(order.orderPrice) || 0,
      num_items: order.items.length,
      eventref: this.pixelService.getEventRef() || '',
    });
  }

  //Monta o objeto do pedido local
  AssembleLocalOrder(nameCustomer: string, tableNumber: number, permissionRequest: boolean, typeOrder: number){
    if(permissionRequest)
    {
      if(typeOrder == 1)
      {
        this.cartService.idTableCardSubject.next(tableNumber.toString())
        this.cacheService.setItemLocal('number_local', tableNumber);

        this.sacola = {
          id_order: 0,
          customerName: nameCustomer,
          id_table: this.typeParam.qrCodeNumber,
          id_companyFK: this.company.company.id_company,
          displayId: '1234',
          products: this.bag,
          orderType: 1,
          cardNumber: 0,
          tableNumber: Number(this.typeParam.numberParam),
          orderPrice: this.calcTotalOrder(),
          orderStatus: '1',
        }

        this.finalizationOrder(this.sacola)

      }
      else if(typeOrder == 2)
      {
        if(this.company.configs.cardConfig == 1){
          this.cartService.idTableCardSubject.next(tableNumber.toString())
          this.cacheService.setItemLocal('number_local', tableNumber);
        }

        this.sacola = {
          id_order: 0,
          customerName: nameCustomer,
          id_table: this.typeParam.qrCodeNumber,
          id_companyFK: this.company.company.id_company,
          displayId: '1234',
          orderType: 1,
          products: this.bag,
          cardNumber: Number(this.typeParam.numberParam),
          tableNumber: tableNumber,
          orderPrice: this.calcTotalOrder(),
          orderStatus: '1',
        }
        this.finalizationOrder(this.sacola)
      }
    }
    else{
      this.toastr.error(`Não é possivel fazer pedido, aguarde o garçom.`, 'Pedido em conta', { positionClass: 'toast-top-right', timeOut: 4000});
    }

  }

  //utilizado para finalizar o pedido local
  private finalizationOrder(pedido: OrderLocal){
    this.OrderTableCard(pedido);
    this.cartService.clearCart()
    this.cacheService.removeItemLocal("product-cart")
  }

  //calcula o total do pedido
  private calcTotalOrder(){
    let total = 0
    this.bag.forEach(product => {
      total += product.totalPrice
    });
    return total
  }

}
