import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pedido } from 'src/app/shared/Interfaces/pedido';

@Component({
  selector: 'app-modal-promotion',
  templateUrl: './modal-promotion.component.html',
  styleUrls: ['./modal-promotion.component.css', "../../../../assets/styles/global/_modal.scss"]
})
export class ModalPromotionComponent implements OnInit{
  desableAll: boolean = false;
  productOrderSelected!: Pedido;
  checked: boolean[] = []
  @Input() productsPromotion: any;
  @Input() productsOrder!: Pedido[];
  @Input() modalShow: boolean = false;
  @Output() promotion: EventEmitter<{productOrder: Pedido, productPromotion: any}> = new EventEmitter<{productOrder: Pedido, productPromotion: any}>();

  flavorQuantity: number[] = []

  ngOnInit(): void {
    this.checked = Array(this.productsOrder.length).fill(false);
    if(this.productsOrder.length == 1){
      this.checked[0] = true;
      let prod!: Pedido;
      this.productOrderSelected = prod

      this.productOrderSelected = this.productsOrder[0];
    }
  }

  ngOnDestroy(): void {
    this.desableAll = false;
    let prod!: Pedido;
    this.productOrderSelected = prod;   
  }

  aceept(){
    const data = {productOrder: this.productOrderSelected, productPromotion: this.productsPromotion}
    this.promotion.emit(data)
  }

  selected(event: any, index: number){
    const data = event;

    data.checked = !data.checked
    this.checked[index] = data.checked

    if(data.checked){

      this.checked.forEach((check, i) => {
        if(index != i){
          this.checked[i] = false;
        }

      });
      let prod!: Pedido;
      this.productOrderSelected = prod

      const productFind = this.productsOrder.find(product => product === data.value)
      if(productFind){
        this.productOrderSelected = productFind;
      }

    }
    else{
      let prod!: Pedido;
      this.productOrderSelected = prod
      // this.desableAll = false;
    }

  }

}
