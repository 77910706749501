import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { additional, categoryAdd, OrderUser, Pedido } from 'src/app/shared/Interfaces/pedido';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { OrderService } from 'src/app/shared/services/Order/order.service';
import { UserService } from 'src/app/shared/services/User/user.service';
import { Location } from '@angular/common';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { paymentForm, Pix } from 'src/app/shared/Interfaces/order';
import { Subscription } from 'rxjs';
import { WebhookService } from 'src/app/shared/services/WeebHook/webhook.service';
import { Coupon } from 'src/app/shared/Interfaces/coupon';
import { Cliente } from 'src/app/shared/Interfaces/customer';
import * as _ from 'lodash';
import { paymentFormCompany, typeInterface } from 'src/app/shared/Interfaces/company';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
@Component({
  selector: 'app-order-confirmation-counter',
  templateUrl: './order-confirmation-counter.component.html',
  styleUrls: ['../order-confirmation/order-confirmation.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class OrderConfirmationCounterComponent implements OnInit, AfterViewInit{
  //#region Variaveis Locais
    private webHookSubscription!: Subscription;
    public typeParam!: typeInterface;
    openModal = false;
    showTopMenu = true;
    eventNavegation = false;
    openLoadingComponent = false;
    abilitButton = false;
    openModalPix = false;
    counterFinalizado = false;
    openCardLoading = false;
    openModalObservation = false;
    observationActive = false;
    hiddenCard: boolean = false;
    corretFormat!: boolean | undefined;
    toastCloseStoreActive = false;
    disableButton = false;
    openCouponView = false;
    desvinculationCoupon = false
    openModalFidelity = false;
    productsDiscount: Pedido[] = []
    couponSelected!: Coupon
    productPromotion: any;
    productInseringPromotion: any;
    modalPromotion = false;
    nameCompany: string = '';
    company!: Company;
    name!: string;
    phoneNumber!: string;
    document: string = '';
    user!: Cliente;
    subTotal: number = 0;
    paymentObject: paymentForm[] = []
    flavorQuantity: number[] = []
    orderObservation: string = '';
    countText = 0;
    productsEmpty: Pedido[] = [];
    paymentFormEmpty!: paymentFormCompany;

    //Objeto do pix
    pixObject: Pix = {
      createdAt: '',
      copyPaste: '',
      qrCode: '',
      id_order: '',
      value: 0,
    }

    //Objeto do pedido
    sacola: OrderUser = {
      id_companyFK: 0,
      id_customerFK: '',
      changeFor: '0',
      document_customer: '',
      orderType: 2,
      orderPrice: 0,
      orderObservation: '',
      orderStatus: '',
      paymentForm: this.paymentObject,
      onlinePay: 0,
      products: this.productsEmpty,
      coupons: false,
      couponType: 0,
      id_coupon: '0',
      discountValue: 0,
      deliveryFee: 0
    };

    //Sessão de observação
    observationSession = {
      checked: false,
      value: 0
    }
    
    //Objeto de metodo de recebimento
    methodReceiptSelected = {
      checked: true,
      value: 2
    };
  
    //Objeto de Tipo de pagamento
    typeMethodSelected = {
      checked: false,
      value: 0
    }
  
    //Objeto de Formas de pagamento
    paymentFormSelected = {
      checked: false,
      value: 0,
      form: this.paymentFormEmpty
    }

  //#endregion

  //#region Inicializadores

  constructor(
    private cacheService: CacheService,
    private router: Router,
    private toastr: ToastrService,
    private orderService: OrderService,
    private cartService: CartService,
    private layout: LayoutComponent,
    private connection: ConnectionService,
    private eventsService: EventsService,
    private userService: UserService,
    private apiService: ApiService,
    private location: Location,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private webHookService: WebhookService,
    ){
      this.connection.nameCompany$.subscribe((newNameCompany) => {
        this.nameCompany = newNameCompany;
      })

      this.eventsService.eventNavegation$.subscribe((newEvent) =>{
        this.eventNavegation = newEvent
      })

      this.connection.company$.subscribe((newCompany) =>{
        this.company = newCompany
        if(this.company.configs.configObservation == 0)
        {
          this.hiddenCard = true;
          this.observationOrder({ checked: true, value: 0, name: '' })
        }
      })

      this.connection.user$.subscribe((User) =>{
        if(User){
          this.user = User
        }
        else if(this.cacheService.getItemLocal('user_key')){
          this.connection.getUser();
        }
      })

      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam
      })

      this.layout.checkScreenSize();
  }

  //Iniciado assim que o component é criado
  ngOnInit(): void {
    this.location.replaceState(`${this.nameCompany}/B`);

    this.disablePullToRefresh();

    this.montarPedido();

    this.webHookSubscription = this.webHookService.getUpdates().subscribe(
      (content: any) => {
        if(content.type == 5){
          const data = content.data
          console.log(data);
          if(this.pixObject.id_order){
            if(data.id_order == this.pixObject.id_order)
              if(data.status == 1){
                clearInterval(this.pixObject.id_interval)
                this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
              }
              else if(data.status == 6){
                clearInterval(this.pixObject.id_interval)
                this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
                this.router.navigate([this.nameCompany, 'pedidos'])
              }
          }

        }
      },
      (error) => {
        console.error('Erro ao receber mensagem do servidor WebHook:', error);
      }
    );
  }

  //Inicializado após o termino do carregamento DOM
  ngAfterViewInit(){
    setTimeout(() => {
      this.name = this.user.name
      this.phoneNumber = this.user.phone
    });
  }

  ngOnDestroy(): void {
    if(this.webHookSubscription) {
      this.webHookSubscription.unsubscribe();
    }
    clearInterval(this.pixObject.id_interval);
  }

  //#endregion

  //#region Funções de estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    @HostListener('window:resize', [])
    onWindowResize(event: any) {
      this.checkScreenSize();
    }

    //Verifica o tamanho da tela, esconde o Menu superior se necessario
    private checkScreenSize(){
      if(window.innerWidth <= 846){

        this.showTopMenu = false;
      }
      else{
        this.showTopMenu = true;
      }
    }

    //Função responsavel para não mostrar o modal caso tenha ido editar o usuario
    alterationEvent(){
      this.eventsService.eventNavegationSubject.next(true)
      this.userService.editingUserSubject.next(true);
      this.router.navigate([this.nameCompany, 'user-data'])
    }

    //O primeiro modal a ser aberto, quando o component inicia
    showModal(){
      setTimeout(() => {
        this.openModal = true
      }, 100);

    }

    //Função que é chamada para fazer a verificação se todos campos estão requeridos para abilitar o botão de finalizar o pedido
    habilitButton() {
      this.abilitButton = this.typeMethodSelected.checked && this.paymentFormSelected.checked;
    }

    viewCoupon(event: any){
      if(event == true){
        this.openCouponView = true;
      }
      else{
        this.openCouponView = false;
        setTimeout(() => {
          window.scrollTo({
            behavior: 'instant' as ScrollBehavior,
            top: document.documentElement.scrollHeight,
            left: 0,
          });
        }, 10);

      }
    }

    showModalObservation(){
      this.openModalObservation = true;
    }

    confirmObservation(){
      this.openModalObservation = false
      this.sacola.orderObservation = this.orderObservation
      if(this.sacola.orderObservation?.length != 0){
        this.observationActive = true;
      }
      else{
        this.observationActive = false;
      }
    }

    cancelObservation(){
      this.openModalObservation = false;
      this.orderObservation = this.sacola.orderObservation

      if(!this.observationActive){
        this.sacola.orderObservation = '';
      }
    }

    valueTextArea(event: any){
      const inputElement = event?.target as HTMLInputElement;

      if(inputElement.value.length <= 200){
        this.orderObservation = inputElement.value
        this.countText = this.orderObservation.length;
      }
      else[
        inputElement.value = this.orderObservation
      ]

    }

    goback(): void{
      if(this.company.company.status == 1){
        this.router.navigate([this.nameCompany, 'carrinho']);
        this.eventsService.eventNavegationSubject.next(false)
      }
      else{
        this.router.navigate([this.nameCompany, 'B']);
        this.eventsService.eventNavegationSubject.next(false)
        this.eventsService.eventTypeReceiptSubject.next(0)
      }

    }

    documentValid(event: any){
      this.sacola.document_customer = event;
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //#region Box Selecteds

      //Card de observação de local no modo Balcão
      observationOrder(data: { checked: boolean, value: number, name: string }){
        this.observationSession = data
        if(data.checked == false && data.value == 0){
          this.removeFees();
          this.removeEdge();
          const paymentEmpty = {checked: false, value: 0, form: this.paymentFormEmpty}
          this.typeMethodSelected = data
          this.paymentFormSelected = paymentEmpty

          this.sacola.localization = ''
          this.sacola.paymentForm = []
          this.paymentObject = [];
        }

        this.sacola.localization = data.name;
        this.habilitButton();
        
      }

      //Card do tipo de pagamento
      typePayment(data: { checked: boolean, value: number })
      {
        this.typeMethodSelected = data
        if(data.checked == false && data.value == 0)
        {
          this.removeFees();
          this.removeEdge();
          const paymentEmpty = {checked: false, value: 0, form: this.paymentFormEmpty}
          this.paymentFormSelected = paymentEmpty;
          // limpa a Forma de pagamento
          this.sacola.paymentForm = [];
          this.paymentObject = [];
          this.sacola.onlinePay = 0;
        }
        this.habilitButton();

      }

      //Card da forma de pagamento
      formPayment(data: { checked: boolean, value: number, form: paymentFormCompany})
      {

        this.paymentFormSelected = data
        if(data.checked == false)
        {
          this.modalPromotion = false;
          this.sacola.paymentForm = []
          this.paymentObject = []
          this.sacola.onlinePay = 0;
          this.removeEdge();
          this.removeFees();
        }
        else{

          if(data.form.activeFee){
            this.addFees(data.form);
          }

          const form: paymentForm = {
            id_formpayment: data.form.id_paymentForm,
            changeFor: "0",
            onlinePay: 0,
            payStatus: 0,
            paymentName: this.paymentFormSelected.form.formName,
            paymentForm: this.paymentFormSelected.value,
            paymentValue: this.sacola.orderPrice.toString()
          }

          if(data.form.formNumber == 0){
            form.onlinePay = 1
            this.sacola.onlinePay = 1;
          }
          else{
            form.onlinePay = 0
            this.sacola.onlinePay = 0;
          }

          //adiciona ele no array e adiciona o array no pedido
          this.paymentObject.push(form)
          this.sacola.paymentForm = this.paymentObject

          if(this.company.configs.edgePix == 1){
            if(data.form.formNumber == 2 || data.form.formNumber == 0){
              this.promotionPayment(this.sacola)
            }
          }
        }

        console.log(this.sacola)
        this.habilitButton();
      }

      //Card do troco
      changeFor(event: any){
        if(typeof event == 'number')
        {
          this.sacola.changeFor = event.toString()
          this.sacola.paymentForm[0].changeFor = event.toString()
        }
      }

    //#endregion

    //#region Pix

      //Quando o usuario seleciona o pix, função responsavel de montalo
      paymentPix(sacola: OrderUser){
        this.openCardLoading = true;
        this.orderService.OrderUserPaymentPix(sacola).subscribe(
          (data) =>{
            this.apiService.paymentPix(data).subscribe((dataPayment) =>{
              this.openModalPix = true;
              this.openCardLoading = false;

              this.pixObject = {
                id_order: data.id_order,
                createdAt: data.createdAt,
                copyPaste: dataPayment.pagamento,
                qrCode: 'data:image/png;base64,' + dataPayment.pagamentoQrCode,
                value: dataPayment.valorTotal
              }
              console.log(this.pixObject);
              this.startVerification(data.id_order, this.company.company.id_company);

            })
          },(error) =>{
            this.openCardLoading = false;

          });
      }

      //Envia uma requisição para o servidor webhook para fazer a verificação
      startVerification(id_order: string, id_company: number){
        this.apiService.startVerification(id_order, id_company).subscribe(
          () =>{
            console.log('O Servidor iniciou a verificação')
            this.pixObject.id_interval = setInterval(() => {
              this.getStatusOrder(id_order)
            }, 10000) 
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Resgata a confirmação de pagamento pix
      getStatusOrder(id_order: string){
        this.apiService.getStatusOrder(id_order).subscribe(
          (data) =>{
            if(data.status == 1){
              clearInterval(this.pixObject.id_interval)
              this.toastr.success('Pagamento Confirmado!', 'Sucesso', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'order-details', this.pixObject.id_order])
            }
            else if(data.status == 10){
              clearInterval(this.pixObject.id_interval)
              this.toastr.warning('Tempo de pagamento expirado!', 'Pix expirado', { positionClass: 'toast-top-right', timeOut: 4000});
              this.router.navigate([this.nameCompany, 'pedidos'])
            }
          },
          (error) =>{

          }
        )
      }

      //Fecha o contador quando o tempo termina
      finalizationCounter(event: any){
        this.counterFinalizado = event
      }

      //Volta para view de pedidos
      backOrders(){
        this.router.navigate([this.nameCompany, 'pedidos']);
      }

    //#endregion

    //#region Taxas

      //Adiciona as taxas ao pedido
      addFees(form: paymentFormCompany){

        const fee = {
          id: form.id_paymentForm,
          value: false,
          percent: false,
          type: form.typeFee,
          fee: 0,
          valueFee: 0
        }

        if(form.value){

          fee.value = true
          fee.fee = form.fee
          fee.valueFee = form.fee

          if(form.typeFee == 1){
            this.sacola.fee = fee
            // desconto
            this.sacola.orderPrice = this.sacola.orderPrice - fee.valueFee;
            this.verifiPrice();
          }
          else{      
            this.sacola.fee = fee
            // acrescimo
            this.sacola.orderPrice = this.sacola.orderPrice + fee.valueFee;
            this.verifiPrice();
          }
        }
        else{
          const percentValue = parseFloat(((this.subTotal * form.fee) / 100).toFixed(2));
          fee.percent = true
          fee.fee = form.fee
          fee.valueFee = percentValue

          if(form.typeFee == 1){
            this.sacola.fee = fee
            // desconto
            this.sacola.orderPrice = parseFloat((this.sacola.orderPrice - percentValue).toFixed(2));
            this.verifiPrice();
          }
          else{
            this.sacola.fee = fee
            // acrescimo
            this.sacola.orderPrice = parseFloat((this.sacola.orderPrice + percentValue).toFixed(2));
            this.verifiPrice();
          }
        }
      }

      //Remove as taxas
      removeFees(){
        if(this.sacola.fee){
          if(this.sacola.fee.type == 1){
            this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.fee.valueFee
          }
          else{
            this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.fee.valueFee
          }

          this.sacola.fee = undefined
        }

      }

      //Verifica se o preço não é abaixo de zero
      verifiPrice(){
        if(this.sacola.orderPrice < 0){
          this.sacola.orderPrice = 0;
        }
      }

    //#endregion

    //#region Coupon

      //Quando o cupom selecionado é ativado
      coupomSelected(event: any){

        if(event.check){
          let coupon: Coupon = event.coupon
          if(coupon.couponType == 1 || coupon.couponType == 3){
            if(coupon.percent != 0)
              {
                const discontFormated = coupon.percent / 100;
                let discont;
                if(this.sacola.orderType == 5){
                  const orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee;
                  discont = orderPrice * discontFormated;
                  this.sacola.orderPrice = orderPrice - discont + this.sacola.deliveryFee;
                }
                else{
                  discont = this.sacola.orderPrice * discontFormated;
                  this.sacola.orderPrice = this.sacola.orderPrice - discont;
                }
                this.sacola.discountValue = discont;
                this.sacola.id_coupon = coupon.id_coupon;
                this.sacola.coupons = true;
                this.sacola.couponType = 1;
            }
            else if(coupon.value != 0){
              let discont = coupon.value
              this.sacola.orderPrice = this.sacola.orderPrice - discont
              this.sacola.discountValue = discont;
              this.sacola.id_coupon = coupon.id_coupon
              this.sacola.coupons = true,
              this.sacola.couponType = 2
            }
            else if(coupon.deliveryFree == true){
              const desconto = this.sacola.deliveryFee
              this.sacola.discountValue = desconto
              this.sacola.id_coupon = coupon.id_coupon,
              this.sacola.coupons = true
              this.sacola.couponType = 3
              this.sacola.orderPrice = this.sacola.orderPrice - this.sacola.deliveryFee
              this.sacola.deliveryFee = 0
            }
          }
          else if(coupon.couponType == 2){
            this.productsDiscount = this.sacola.products.filter(prodOrder => coupon.discountProducts.includes(prodOrder.id_product.toString())).map(prodOrder => ({ ...prodOrder }));
          
            if (this.productsDiscount.length === 1) {
              this.couponSelected = coupon
              this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
            } else if (this.productsDiscount.length > 1) {
              if(coupon.percent != 0){
                this.openModalFidelity = true;
                this.couponSelected = coupon;
              }
              else{
                this.couponSelected = coupon
                this.applyCouponDiscount({ coupon, product: this.productsDiscount[0] });
              }

            } 
          }
        }
        else{
          this.deslectedCupom();
        }
      }
  
      //Aplica o desconto de cupons do tipo produto 
      applyCouponDiscount(event: any) {
        const product = event.product;
        const coupon = event.coupon;
        const matchingItem = this.sacola.products.filter(productOrder => this.equalsObjectsCompare(product, productOrder, ["id_product", "observations"]));

        if(matchingItem){

          const discount = this.calculateDiscount(matchingItem[0], coupon);
          this.applyDiscountToProduct(matchingItem[0], discount);
  
          this.sacola.discountValue = discount;
          this.sacola.id_coupon = coupon.id_coupon;
          this.sacola.coupons = true;
          this.sacola.couponType = 2;
          this.sacola.orderPrice = this.sacola.orderPrice - discount
          this.toastr.clear();
          this.toastr.success(`Cupom aplicado`, 'Sucesso', { positionClass: 'toast-top-right', timeOut: 3000});
        }

        this.openModalFidelity = false;
      }
  
      //Aplica o disconto no produto
      private applyDiscountToProduct(matchingItem: any, discount: number) {
        if (matchingItem.quantity > 1) {
          //Novo Item
          const productDiscount = JSON.parse(JSON.stringify(matchingItem));
          productDiscount.totalPrice = matchingItem.totalPrice / matchingItem.quantity;
          productDiscount.oldPrice = productDiscount.totalPrice;
          productDiscount.discountValue = discount;
          productDiscount.totalPrice -= discount;
          productDiscount.discount = true;
          productDiscount.quantity = 1;

          if (productDiscount.flavorsValue) {
            productDiscount.flavorsValue = productDiscount.baseFlavorsValue;
          }   
          const priceDiscount = matchingItem.totalPrice / matchingItem.quantity;

          this.sacola.products.push(productDiscount);

          //Item Antigo
          if (matchingItem.flavorsValue) {
            matchingItem.flavorsValue -= matchingItem.baseFlavorsValue;
          }
          matchingItem.totalPrice = priceDiscount * (matchingItem.quantity - 1);
          matchingItem.quantity -= 1;
        } else {
          matchingItem.oldPrice = matchingItem.totalPrice;
          matchingItem.totalPrice -= discount;
          matchingItem.discount = true;
          matchingItem.discountValue = discount;

          if (matchingItem.flavorsValue) {
            matchingItem.flavorsValue -= matchingItem.baseFlavorsValue;
          }
        }

        let count = 0
        this.sacola.products.forEach((product, index) =>{
          count = 0
          product.categories.forEach(categorie =>{
            let value = 0
            categorie.additional.forEach(additional =>{
              value += additional.quantitySub
              if(categorie.flavorCategory == 1)
              {
                count += additional.quantitySub
              }
            })
          })
          this.flavorQuantity.push(count)
        })
      }
  
      //Calcula quanto de desconto o produto recebera
      private calculateDiscount(matchingItem: any, coupon: any): number {
        if (coupon.percent != 0) {
          if(matchingItem.quantity > 1){
            const totalPrice = matchingItem.totalPrice / matchingItem.quantity
            return totalPrice * (coupon.percent / 100);
          }
          else{
            return matchingItem.totalPrice * (coupon.percent / 100);
          }

        } else if (coupon.value != 0) {
          return coupon.value;
        }
        return 0;
      }
  
      //Quando o usuario cancela a seleção de produtos que ganharão o desconto
      cancelSelectCoupon(){
        this.desvinculationCoupon = true;
        this.openModalFidelity = false
        setTimeout(() => {
          this.desvinculationCoupon = false;
        }, 100);
      }
  
      //Desseleciona o cupom e retira suas propriedades no pedido
      deslectedCupom(){
  
        let coupon!: Coupon;
        this.couponSelected = coupon
  
        this.productsDiscount = []
  
        if(this.sacola.discountValue)
        {
          this.sacola.orderPrice = this.sacola.orderPrice + this.sacola.discountValue;
          this.sacola.id_coupon = '0';
          this.sacola.coupons = false;
          this.sacola.discountValue = 0;
          this.sacola.couponType = 0;
        }



        if(this.sacola.discountValue)
        {  
          this.sacola.id_coupon = '0';
          this.sacola.coupons = false;
          this.sacola.discountValue = 0;
          this.sacola.couponType = 0;
        }

        this.sacola.products.forEach(product => {
          if(product.discount){
            product.discount = false,
            product.discountValue = 0,
            product.totalPrice = product.oldPrice
            product.oldPrice = 0
            this.joinDuplications(this.sacola.products)
          }
        });
      }

    //#endregion

    //#region Promoção para pagamento Pix (Borda Pix)

      //Detecta, se existe promoção para aqueles produtos
      promotionPayment(products: OrderUser){
        this.apiService.promotionPaymentForm(products).subscribe(
          (data) =>{
            if(!data.empty){
              this.productInseringPromotion = data.principalProducts;
              this.productPromotion = data.promoProducts[0];
              this.modalPromotion = true;
            }
          },
          (error) =>{
            console.log(error);
          }
        )
      }

      //Faz a lógica de localizar e adicionar a promoção no produto
      promotionSelected(data:{productOrder: Pedido, productPromotion: any}){   
        const product = this.sacola.products.filter(prod => this.equalsObjectsCompare(data.productOrder, prod, ["id_product", "observations", "discount"]))[0];

        if(product){
          if(product.quantity > 1){

            const newProduct = JSON.parse(JSON.stringify(product));
            newProduct.quantity = 1;
            newProduct.totalPrice = newProduct.baseValue;       
            if (newProduct.flavorsValue) {
              newProduct.flavorsValue = newProduct.baseFlavorsValue;
            }

            product.quantity = product.quantity - 1;
            product.totalPrice -= newProduct.baseValue;
            if (product.flavorsValue) {
              product.flavorsValue -= newProduct.baseFlavorsValue;
            }

            this.sacola.products.push(newProduct);
            data.productPromotion.promotionPay = true;
            newProduct.categories.push(data.productPromotion);

            let count = 0
            this.sacola.products.forEach((product, index) =>{
              count = 0
              product.categories.forEach(categorie =>{
                let value = 0
                categorie.additional.forEach(additional =>{
                  value += additional.quantitySub
                  if(categorie.flavorCategory == 1)
                  {
                    count += additional.quantitySub
                  }
                })
              })
              this.flavorQuantity.push(count)
            })

          }
          else{
            data.productPromotion.promotionPay = true;
            product.categories.push(data.productPromotion)
          }
          this.modalPromotion = false;
        }
        else{
          this.modalPromotion = false;
        }

      }

      //Remove a borda Pix gratis
      removeEdge(){
        this.sacola.products.forEach(products =>{
          const indexCat = products.categories.findIndex(categorie => categorie.promotionPay)
          if(indexCat != -1){
            products.categories.splice(indexCat, 1)
          }
        })
        this.joinDuplications(this.sacola.products);
      }

    //#endregion

    //#region Montagem e finalização do pedido

      //Monta o objeto de pedido de usuario, tanto para Pedido em balcão quanto em delivery
      montarPedido(){
        this.sacola = {
          id_customerFK: this.user.id_customer,
          document_customer: this.document,
          changeFor: '0',
          id_companyFK: this.company.company.id_company,
          products: JSON.parse(JSON.stringify(this.orderService.bag)),
          orderType: 2,
          orderStatus: '1',
          paymentForm: this.paymentObject,
          orderObservation: '',
          onlinePay: 0,
          orderPrice: this.calcTotalOrder(),
          id_coupon: '0',
          discountValue: 0,
          coupons: false,
          couponType: 0,
          deliveryFee: 0,

        }

        this.sacola.orderPrice = this.calcTotalOrder();

        let count = 0;

        this.sacola.products.forEach((product, index) =>{
          count = 0
          product.categories.forEach(categorie =>{
            let value = 0
            categorie.additional.forEach(additional =>{
              value += additional.quantitySub
              if(categorie.flavorCategory == 1)
              {
                count += additional.quantitySub
              }
            })
          })
          this.flavorQuantity.push(count)
        })
      }

      //Manda o pedido para o serviço e limpa os itens do carrinho
      finalizationOrder(){
        this.disableButton = true;
        this.apiService.verifiStatusCompany(this.company.company.id_company).subscribe(
          (data) =>{
            if(data[0].open){
              this.eventsService.eventNavegationSubject.next(false)
              this.eventsService.eventTypeReceiptSubject.next(0)

              if(this.sacola.onlinePay == 1){
                this.paymentPix(this.sacola);
              }
              else{
                this.orderService.OrderUser(this.sacola);
                this.router.navigate([this.nameCompany, 'processing']);
              }
        
              this.cartService.clearCart();
            }
            else{
              if(!this.toastCloseStoreActive)
                {
                  this.toastCloseStoreActive = true;
                  this.toastr.error('Desculpe, mas a loja já fechou!', 'Loja Fechada',
                  { positionClass: 'toast-top-right', timeOut: 0, tapToDismiss: true,}
                  ).onHidden.subscribe(() =>{ this.toastCloseStoreActive = false});
                }
    
                this.connection.Empresa(this.nameCompany, this.typeParam.type).subscribe(
                  (data) =>{
                    console.log('Status da loja atualizado');
                  },
                  (error) =>{
                    console.log('Erro inesperado', error)
                  }
                )
            }
          },
          (error) =>{

          })
      }

      //Calcula o total da ordem para ser montado o objeto e feito o request
      calcTotalOrder(){
        let total = 0
        let subTotal = 0
        this.sacola.products.forEach(product => {
          if(product.discount){
            subTotal += product.totalPrice + product.discountValue
          }
          else{
            subTotal += product.totalPrice
          }
          total += product.totalPrice
        });

        this.subTotal = subTotal

        return total
      }
    //#endregion

    //#region Comparação Produtos | Duplicações

      // Comparando APENAS as propriedades específicas dos produtos / categorias / adicionais
      equalsObjectsCompare(obj1: any, obj2: any, keysToCompare: any): boolean {
        if (!obj1 || !obj2) return false;
      
        // Comparando APENAS as propriedades específicas dos Produtos
        if (!_.isEqual(_.pick(obj1, keysToCompare), _.pick(obj2, keysToCompare))) {
          return false;
        }
      
        // Comparando categorias
        const categories1 = obj1.categories?.map((categorie: any) => this.extractCategoryData(categorie)) || [];
        const categories2 = obj2.categories?.map((categorie: any) => this.extractCategoryData(categorie)) || [];
      
        return _.isEqual(categories1, categories2);
      }
      
      //Extrai a comparação dos adicionais e monta o objeto de comparação da categoria
      extractCategoryData(category: any): object {
        return {
          id_category: category.id_category,
          additional: category.additional?.map((additional: any) => this.extractAdditionalData(additional)) || []
        };
      }
      
      //Compara APENAS as propriedades específicas dos Adicionais
      extractAdditionalData(additional: any): object {
        return _.pick(additional, ["add_id_product", "quantitySub"]);
      }

      //Junta as Duplicações que foram salvas
      joinDuplications(products: Pedido[]){
        for (let i = 0; i < products.length; i++) {
          const product = products[i];
          const duplicates = products.filter((prod, index) => prod.id_product === product.id_product && index > i);
      
          duplicates.forEach(duplicate => {
            if (this.equalsObjectsCompare(product, duplicate, ["id_product", "observations", "discount"])) {
              console.log('Esses dois aqui, são iguais:', [product, duplicate]);
      
              // Atualiza a quantidade e o preço total
              product.quantity += duplicate.quantity;
              product.totalPrice = product.unitPrice * product.quantity;
      
              // Remove o item duplicado
              const indexToRemove = products.indexOf(duplicate);
              if (indexToRemove > -1) {
                products.splice(indexToRemove, 1);
              }
            }
          });
        }
      }

    //#endregion
//#endregion

}
