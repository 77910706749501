import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { typeInterface } from 'src/app/shared/Interfaces/company';
import { Cliente } from 'src/app/shared/Interfaces/customer';
import { Fidelity } from 'src/app/shared/Interfaces/fidelity';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import IMask from 'imask';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css', "../../../assets/styles/global/_modal.scss"]
})
export class UserViewComponent implements OnInit{

  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @ViewChild('inputName') inputName!: ElementRef;
  @ViewChild('inputBirth') inputBirth!: ElementRef;
  private phoneMask: any;
  private birthMask: any;
  existingUser = false;
  existingUserData: any;
  openLogOut: boolean = false;
  user!: Cliente;
  alterationButton: boolean = false;
  nameCompany: string = '';
  userConfirmation = false;
  name: string = '';
  birthData: string = '';
  phoneNumber: string = '';
  id_customer: string = '';
  alterationUser = false;
  lengthInputName: any;
  lengthInputPhone = false;
  lengthInputBirth = true;
  company!: Company;
  corretFormatPhoneNumber: any;
  correctFormatBirth: any
  fidelityUser!: Fidelity;
  fidelityGet: boolean = false;
  showTopMenu: boolean = false;
  typeParam!: typeInterface;
  requiredBirth = false;

  constructor(
    private layout: LayoutComponent,
    private toastr: ToastrService,
    private router: Router,
    private connection: ConnectionService,
    private cacheService: CacheService,
    private apiService: ApiService
  ){
    this.connection.nameCompany$.subscribe((newNameCompany) => {
      this.nameCompany = newNameCompany
    })

    this.connection.typeParam$.subscribe((newTypeParam) => {
      this.typeParam = newTypeParam
    })

    this.connection.fidelity$.subscribe((data) =>{
      this.fidelityUser = data;
      this.fidelityGet = true
    })

    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
      if(this.company.company.id_company.toString() == 'f33fcb89-4d42-4745-b090-212cd6f359bd'){
        this.requiredBirth = true;
      }
    })

    this.connection.user$.subscribe((User) =>{
      if(User)
      {
        this.user = User
        this.userConfirmation = true
        this.updateMask();
      }
    })
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.addMask();
    this.onWindowResize();
  }


//#region Log in User

  getFidelity(){
    if(this.company){
      if(this.user){
        this.apiService.getFidelity(this.user.id_customer, this.company.company.id_company).subscribe(
          (data) =>{
            this.fidelityUser = data;
            this.fidelityGet = true
            console.log(this.fidelityUser);
          },
          (error) =>{
            console.log(error);
          }
        )
      }
    }

  }

  @HostListener('window:resize', [])
  onWindowResize(event?: any) {
    if(window.innerWidth <= 846){

      this.showTopMenu = false;
    }
    else{
      this.showTopMenu = true;
    }
    this.layout.checkScreenSize();
  }

  //Acionado toda vez que uma letra é digitada no input do nome
  onInputChangeName(event:any)
  {
    const length = event.target.value.length
    if(length >= 3 )
    {
      this.lengthInputName = true;
    }
    else{
      this.lengthInputName = false;
    }

  }

  //Valida se o input está prenchido corretamente
  validateBirthDate() {
    if(this.birthData.length > 0){
      this.correctFormatBirth = this.birthData.length === 10;
      if(this.birthData.length < 10){
        this.lengthInputBirth = false;
      }
      else{
        this.lengthInputBirth = true;
      }
    }
    else{
      this.correctFormatBirth = undefined;
      this.lengthInputBirth = true;
    }

  }

  //Volta a pagina
  goback(): void{
    if(this.typeParam.type == 3)
    {
      this.router.navigate([this.nameCompany, 'B']);
    }
    else{

      this.router.navigate([this.nameCompany]);
    }
  }

  addMask(){
    setTimeout(() => {
      if(this.inputPhone && this.inputBirth){
        this.phoneMask = IMask(this.inputPhone.nativeElement, {
          mask: '(00)00000-0000',
        });
        this.birthMask = IMask(this.inputBirth.nativeElement, {
          mask: '00/00/0000',
        });
        this.phoneMask.updateValue();
        this.birthMask.updateValue();
        console.log('Mask aplicado:', {phone: this.phoneMask, birth: this.birthMask})
      }
    }, 0);
  }

  updateMask(){
    if(this.phoneMask && this.birthMask){
      setTimeout(() => {
        this.phoneMask.updateValue();
        this.birthMask.updateValue();
        console.log('Mask atualizado:', {phone: this.phoneMask, birth: this.birthMask})
      }, 0);
    }
  }

  //#region Phone Number

    //Formata e valida o telefone do usuário
    formatPhoneNumber(phone: string): string {
      if (!phone) return '';
    
      let rawValue = phone.replace(/\D/g, '');
    
      const firstPart = rawValue.substring(0, 2);
      const secondPart = rawValue.substring(2, 7);
      const thirdPart = rawValue.substring(7, 11);
    
      if (rawValue.length <= 2) {
        return `(${firstPart}`;
      } else if (rawValue.length <= 7) {
        return `(${firstPart})${secondPart}`;
      } else {
        return `(${firstPart})${secondPart}-${thirdPart}`;
      }
    }

    //Valida se o input está prenchido corretamente
    validatePhoneNumber() {
      if(this.phoneNumber.replace(/\D/g, '').length > 0){
        this.corretFormatPhoneNumber = this.phoneNumber.replace(/\D/g, '').length === 11;

        if(this.phoneNumber.replace(/\D/g, '').length < 11){
          this.lengthInputPhone = false;
        }
        else{
          this.lengthInputPhone = true;

          this.getUserIsPhone(this.phoneNumber);
        }
      }
      else{
        this.corretFormatPhoneNumber = undefined;
        this.lengthInputPhone = false;
      }

    }

    //Busca o usuario pelo seu telefone
    getUserIsPhone(phoneNumber: string){
      let formatted = this.formatPhoneNumber(phoneNumber);

      if(formatted.length === 14){
        this.apiService.getUsuario(formatted).subscribe(
          (data) => {
            this.handleSuccessfulResponse(data)
          },
          (error) => {
            console.log('Usuario não cadastrado');
            this.handleError(error)
          }
        )
      }
    }

  //#endregion


  //#region Confirm

    //Assim que confirmado ele monta os objetos necessarios com os valores dos campos
    submitForm(){
      let user = this.cacheService.getItemLocal('user_key')
      let userAddress = this.cacheService.getItemLocal('Address_Saved')
      let cliente: Cliente;
      
      if(this.existingUserData){
        if(this.existingUserData.response.customer.name != this.name || this.existingUserData.response.customer.phone != this.phoneNumber || this.existingUserData.response.customer.birthDate != this.birthData)
          {
            this.alterationUser = true;
            if(this.id_customer != this.existingUserData.response.customer.id_customer){
              this.id_customer = this.existingUserData.response.customer.id_customer
            }
        }
        cliente = {
          name: this.name,
          phone: this.phoneNumber,
          id_customer: this.id_customer,
          document: '',
          birthDate: this.birthData
        }
      }
      else{
        cliente = {
          name: this.name,
          phone: this.phoneNumber,
          id_customer: '',
          document: '',
          birthDate: this.birthData
        } 
      }
  
      if(this.existingUser == false)
        {
          this.apiService.postUsuario(cliente).subscribe(
            (data) => {
              console.log('Usuario cadastrado com sucesso');
              this.id_customer = data.response.Customer.id_customer
  
              const userKey = {
                phone: this.phoneNumber,
                id_customer: this.id_customer,
                name: this.name,
                birthDate: this.birthData
              }
    
              if(user){
                if(data.response.Customer.id_customer != user.id_customer){
                  this.cacheService.removeItemLocal('Address_Saved')
                  this.cacheService.setItemLocal('user_key', userKey)
                }
              }
              else{
                this.cacheService.setItemLocal('user_key', userKey)
              }
  
              if(userAddress)
              {
                this.cacheService.removeItemLocal('Address_Saved')
              }
    
              this.connection.updateUser(data.response.Customer)
  
              this.toastr.success('Usuário cadastrado', 'Successo', { positionClass: 'toast-top-right', timeOut: 2000});
            },
            (error) => {
              console.error('Erro ao cadastrar usuario:', error);
              this.apiService.getUsuario(this.phoneNumber).subscribe(
                (data) => {
                  this.handleSuccessfulResponse(data)
                },
                (error) => {
                  this.handleError(error)
                }
              )
            }
          );
      }
      else{
        cliente.phone = this.phoneNumber;
        this.pathUser();
  
      }
  
    }

    pathUser(){
      if(this.alterationUser){
        this.apiService.patchUser(this.id_customer, this.name, this.phoneNumber, this.birthData).subscribe(
          (data) =>{
  
            const userKey = {
              phone: data.response.phone,
              id_customer: data.response.id_customer,
              name: data.response.name,
              birthDate: data.response.birthDate
            }
  
            this.cacheService.setItemLocal('user_key', userKey);
            this.connection.updateUser(data.response);
          },
          (error) =>{
            console.log('Erro ao editar usuario:')
          })
      }
      else{
        const user = this.cacheService.getItemLocal('user_key');
  
        const userKey = {
          phone: this.existingUserData.response.customer.phone,
          id_customer: this.existingUserData.response.customer.id_customer,
          name: this.existingUserData.response.customer.name,
          birthDate: this.existingUserData.response.customer.birthDate
        }
  
        if (user) {
          if (this.id_customer !== user.id_customer) {
            this.cacheService.removeItemLocal('userAddress');
            this.cacheService.setItemLocal('user_key', userKey);
          }
        } else {
          this.cacheService.setItemLocal('user_key', userKey);
        }
  
        this.connection.updateUser(this.existingUserData.response.customer);
  
      }
    }
  
    // Função para lidar com a resposta bem-sucedida
    private handleSuccessfulResponse(data: any): void {
      this.name = data.response.customer.name;
      this.birthData = data.response.customer.birthDate
      this.id_customer = data.response.customer.id_customer;
      this.updateMask();
      this.existingUser = true;
      this.alterationButton = true;
      this.lengthInputName = true;
      this.lengthInputPhone = true;
      this.corretFormatPhoneNumber = true;
      this.existingUserData = data;

      if(this.birthData){
        if(this.birthData.length == 10 || this.birthData.length == 0){
          this.correctFormatBirth = true;
          this.lengthInputBirth = true;
        }
      }
      else{
        this.correctFormatBirth = true;
        this.lengthInputBirth = true;
      }

  
      this.inputPhone.nativeElement.blur();
    }
  
    // Função para lidar com erros
    private handleError(error: any): void {
  
      this.name = '';
      this.existingUser = false;
      if(this.name.length >= 3)
      {
        this.lengthInputName = true;
      }
      else{
        this.lengthInputName = false;
      }
  
      this.inputPhone.nativeElement.blur();
      this.inputName.nativeElement.focus();
    }

  //#endregion

//#endregion

  disconnectUser(){
    this.openLogOut = false;
    this.cacheService.removeItemLocal('userAddress')
    this.cacheService.removeItemLocal('user_key');
    let clientEmpty!: Cliente;
    this.connection.updateUser(clientEmpty)
    this.user = clientEmpty
    this.userConfirmation = false;
    this.phoneNumber = '';
    this.name = '';
    this.birthData = '';
    this.corretFormatPhoneNumber = undefined;
    this.correctFormatBirth = undefined;
    this.lengthInputBirth = false;
    this.lengthInputName = undefined;
    this.lengthInputPhone = false;

    this.addMask();
  }

  navigateFidelity(){
    this.router.navigate([this.nameCompany, 'fidelity']);
  }

  navigateCoupons(){
    this.router.navigate([this.nameCompany, 'coupons']);
  }


}
