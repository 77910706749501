<app-top-menu-two *ngIf="showTopMenu"></app-top-menu-two>
<div style="max-width: 800px; margin: auto; height: 100vh;">
  <div class="navegation-header">
    <div class="navegation-header__icon" (click)="goback()">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
    <div class="navegation-header__tittle">
      <h3>Novo endereço</h3>
    </div>
  </div>
  <div class="main-address">
    <div class="text-initial">
      Qual é seu endereço?
    </div>
    <div class="address">
      <div class="address-title">
      </div>
      <div id="conteudo" class="address-content" *ngIf="!requestApi">
        <div class="address-header">
          <span style="font-weight: 600; font-size: 14px;">Digite seu CEP:</span>
          <!-- <span class="not-cep" (click)="this.requestApi = true">Não sei meu CEP!</span> -->
        </div>
        <div class="d-flex flex-column box-input-address">
          <form style="display: flex;flex-direction: column; gap: 10px;">
            <input [(ngModel)]="cep" name="address" class="input-address" type="number" placeholder="123456-789" maxlength="8" (input)="onInputChange($event)">
          </form>
          <div class="result-searsh" *ngIf="this.cep.length == 8 && showAddress" (click)="this.requestApi = true">
            <div class="result-icon">
              <i class="fa-solid fa-location-dot"></i>
            </div>
            <div class="result-content">
              <div>{{ address.street }}</div>
              <div>{{ address.neighborhood }}, {{ address.city }} - {{ address.state }}, {{ address.postalCode }}</div>
            </div>
          </div>
        </div>
        <div class="button-my-localization">
          <!-- <button (click)="getLocalization()">Usar minha localização</button> -->
          <button (click)="this.requestApi = true">Não sei meu CEP!</button>
        </div>
      </div>
      <div>
        <div class="address" *ngIf="requestApi">
          <div class="address-title">
          </div>
          <div id="conteudo" class="address-content">
            <form class="d-flex box-input-address flex-column" style="gap: 5px;" (ngSubmit)="openModalAddress()">
              <div class="div-box">
                <span class="span-inputs">Rua:*</span>
                <input class="inputs" name="street" [(ngModel)]="address.street" required="required" formatation>
              </div>

              <div class="div-box">
                <span class="span-inputs">Número:*</span>
                <input type="number" class="inputs" name="number" [(ngModel)]="address.number" required="required" formatation>
              </div>

              <div class="div-box">
                <span class="span-inputs">Bairro:*</span>
                <input class="inputs" type="text" name="neighborhood" [(ngModel)]="address.neighborhood" required="required" formatation>
              </div>

              <div class="div-box">
                <span class="span-inputs">Cidade:*</span>
                <input class="inputs" type="text" name="city" [(ngModel)]="address.city" required="required" formatation>
              </div>

              <div class="div-box">
                <span class="span-inputs">Complemento:</span>
                <input class="inputs" type="text" name="complement" [(ngModel)]="address.complement" formatation>
              </div>

              <div class="div-box">
                <span class="span-inputs">Ponto de referência:</span>
                <input class="inputs" type="text" name="point" [(ngModel)]="address.reference" formatation>
              </div>

              <div class="div-fixed" *ngIf="requestApi">
                <button type="submit" [ngClass]="{'disabled-positive' : address.city.length <= 3 || address.neighborhood.length <= 3 || address.street.length <= 3 || address.number == '0'}">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-card-loading [openCard]="showLoading" [labelPerson]="'Aguarde...'"></app-card-loading>

  <!--Modal Titulo do Endereço-->
  <div class="modal" [ngClass]="{'show': openModalAskTitle}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 tittle-modal" id="staticBackdropLabel" style="font-size: 19px !important;">Endereço</h1>
        </div>
        <div class="modal-body" style="padding: 0;">
          <form (ngSubmit)="addAddress()" style="text-align: center; width: 100%;">
            <div class="text-area">
              <span style="font-weight: 100;">Adicione um titulo ao seu endereço</span>
              <div class="box-address">
                <div class="box-address__div" (click)="selectIdentifier('Casa')">
                  <span><i class="fa-solid fa-house" style="margin-right: 7px;"></i>Casa</span>
                  <!-- <checkbox [groupName]="'addressIdentifier'" [isChecked]="address.identifier === selectedAddressIdenfier"></checkbox> -->
                  <checkbox-radius (checkboxChange)="onCheckboxChange($event)" [checkboxData]="this.optionHome"></checkbox-radius>
                </div>
                <div class="box-address__div" (click)="selectIdentifier('Trabalho')">
                  <span><i class="fa-solid fa-briefcase" style="margin-right: 7px;"></i>Trabalho</span>
                  <!-- <checkbox [groupName]="'addressIdentifier'" [isChecked]="address.identifier === selectedAddressIdenfier"></checkbox> -->
                  <checkbox-radius (checkboxChange)="onCheckboxChange($event)" [checkboxData]="this.optionWork"></checkbox-radius>
                </div>
                <div class="box-address__div" (click)="selectIdentifier('Escola')">
                  <span><i class="fa-solid fa-school" style="margin-right: 7px;"></i>Escola</span>
                  <!-- <checkbox [groupName]="'addressIdentifier'" [isChecked]="address.identifier === selectedAddressIdenfier"></checkbox> -->
                  <checkbox-radius (checkboxChange)="onCheckboxChange($event)" [checkboxData]="this.optionSchool"></checkbox-radius>
                </div>
                <div class="button-modal-person" (click)="this.showInput = !this.showInput">
                  <span>Personalizar</span>
                </div>
              </div>
              <input placeholder="Ex: Casa, Trabalho, Escola" name="identifier" *ngIf="this.showInput" [(ngModel)]="address.identifier" formatation>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="btn modal-btn-negative" (click)="openModalAskTitle = false;">Cancelar</button>
              <button type="submit" class="btn modal-btn-positive" [ngClass]="{'disabled-positive' : address.identifier == '' && address.identifier.replace(' ', '').length == 0}">Confirmar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>

