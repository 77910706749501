import { Component, EventEmitter, HostListener, Injectable, Input, OnInit, Output } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { typeInterface } from 'src/app/shared/Interfaces/company';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrl: './top-header.component.css'
})
@Injectable({
  providedIn: 'root'
})
export class TopHeaderComponent implements OnInit {

  @Input() requestSuccess!: boolean;
  @Output() showInput = new EventEmitter<boolean>();
  @Output() firstClick = new EventEmitter<boolean>();

  //#region Variaveis
    typeParam!: typeInterface;
    company!: Company;
    buttonClicked = false;
    nameFantasyCompany: string = '';
    imgLogo: string = '';
    includeDivHeader = false;
    scrollTopPosition: number = 0;
    isClicked = false;
  //#endregion

  //#region Inicializadores
    constructor(private connection: ConnectionService){}

    ngOnInit(): void {
      this.connection.company$.subscribe((newCompany) =>{
        if(newCompany){
          this.company = newCompany
          this.nameFantasyCompany = this.company.company.companyName.toUpperCase();
          this.imgLogo = this.company.company.logo;
        }

      })
      this.connection.typeParam$.subscribe((newTypeParam) => {
        this.typeParam = newTypeParam;
      })
    }
  //#endregion

  //#region Funções de estilo (Style)

    //Assim que clicado, da um scroll até o topo e abre o input
    scrollToTopInputShow(){
      this.showInput.emit(true);
      this.firstClick.emit(true);
      window.scrollTo({ top: 0, behavior: 'auto'});
    }

    //assim que clicado ele da um scroll até o topo da pagina
    scrollToTop(){
      const scrollTop = 0;
      window.scrollTo({ top: scrollTop, behavior: 'auto'});

      this.isClicked = true;

      setTimeout(() => {
        this.isClicked = false;
      }, 500);

    }

    //Inclui a div quando o usuario da scroll até um ponto
    includeDivBar(){
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if(window.innerWidth <= 846)
      {
        if(this.scrollTopPosition >= 140)
        {
          this.includeDivHeader = true;
        }
        else{
          this.includeDivHeader = false
        }
      }
      else{}
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): boolean  {
      this.includeDivBar();
      return false;
    }

    @HostListener('window:resize', [])
    onResize(){
      this.includeDivBar()
    }
  //#endregion

}
