import { Directive, ElementRef, HostListener, Optional, Self } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[formatation]'
})
export class FormatationDirective {
  // regexEmoji = /([\uD800-\uDBFF][\uDC00-\uDFFF]|\u200D|\uFE0F|\u2764)/g;
  regexEmoji = /([\uD800-\uDBFF][\uDC00-\uDFFF]|\u200D|\uFE0F|[\u2600-\u27BF])/g;
  regexAspas = /['"]/g;

  constructor(private el: ElementRef, @Optional() @Self() private ngModel: NgModel) { }

  @HostListener('input', ['$event']) onInput(event: any) {
    const valorOriginal = this.el.nativeElement.value;

    const valorFiltrado = valorOriginal.replace(this.regexEmoji, '').replace(this.regexAspas, '');

    if (valorOriginal !== valorFiltrado) {
      this.el.nativeElement.value = valorFiltrado;

      // Se ngModel estiver presente, atualiza também o valor do modelo
      if (this.ngModel) {
        this.ngModel.control?.setValue(valorFiltrado, { emitEvent: false });
      }
    }
  }
}
