import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import {Company, ConnectionService } from '../../services/Connection/connection.service';
import { ScrollService } from '../../services/Scroll/scroll.service';
import { SharedService } from '../../services/shared/shared.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CacheService } from '../../services/Cache/cache.service';
import { EventsService } from '../../services/events/events.service';
import { Categorias } from 'src/app/shared/Interfaces/company';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.css'
})
export class TopBarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('listBar') listBar!: ElementRef
  @ViewChild('topBar') topBar!: ElementRef;

  @ViewChildren('navlink') navlinksTop!: QueryList<ElementRef>;
  @ViewChildren('bar') barTop!: QueryList<ElementRef>;

  @Input() sections!: QueryList<ElementRef>
  @Input() verifiScrolling!: boolean;
  @Input() tabela!: Categorias[];
  @Input() tabelaFiltrada!: Categorias[]

  private startTime!: number;
  private scrollTimeout: any;
  scrollTopPosition: number = 0;
  fixedbar = false;
  cachePosition: number = 0;
  scrollIndex: number = 0;
  scrollPosition: number = 0;
  filter: string = '';
  sectionPositions: number[] = [];
  currentSectionIndex = 0;
  barra = false;
  pauseFunctions = false;
  company!: Company;


  private scrollPositionSubscription!: Subscription;
  private scrollIndexSubscription!: Subscription;

  constructor(private router: Router, private connection: ConnectionService, private eventService: EventsService, private scrollService: ScrollService, private sharedService: SharedService, private elementRef: ElementRef, private renderer: Renderer2, private cacheService: CacheService){
  }

  ngOnInit(): void {
    this.sharedService.valueShared$.subscribe((newValue) => {
      this.tabelaFiltrada = newValue;
    });
    this.sharedService.filter$.subscribe((newValue) => {
      this.filter = newValue;
    });
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['tabelaFiltrada'])
    {
      if(!changes['tabelaFiltrada'].firstChange){
        setTimeout(() => {
          this.colorBar(0)
        }, 10);

      }
    }

  }

  getDynamicClasses() {
    const dynamicClasses: { [key: string]: boolean } = {
      'active-bar': this.barra
    };

    if (this.barra && this.company.configs.colorCompany) {
      dynamicClasses[`active-bar.${this.company.configs.colorCompany}`] = true;
    }

    return dynamicClasses;
  }

  ngAfterViewInit(): void {
    if (this.verifiScrolling) {
      this.scroll();

    }
    else{

      setTimeout(() => {
        window.scrollTo({
          behavior: 'instant' as ScrollBehavior,
          top: 0,
          left: 0
        });
      }, 1000);


      setTimeout(() => {
        this.colorBar(0);
        this.calculateSectionPositions();
      }, 2000);
    }
  }

  ngOnDestroy(): void {
    const index = this.findCurrentSectionIndex(window.scrollY);
    this.scrollService.setScrollPosition(window.scrollY);
    this.scrollService.setScrollIndex(index);
    this.scrollService.scrollingSubject.next(true);
    this.cacheService.setItemSession('index', index);

  }

  scroll(){
    this.cachePosition = this.scrollService.getScrollPosition();
    this.scrollPosition = this.scrollService.getScrollIndex();


    setTimeout(() => {
      window.scrollTo({
        behavior: 'instant' as ScrollBehavior,
        top: this.cachePosition - 55,
        left: 0
      });
    }, 10);


    this.colorBar(this.scrollPosition);
    this.pauseFunctions = true;
  }

  calculateSectionPositions() {
    // Assuma que você tem uma lista de seções no seu componente
    this.sections.forEach(section => {
      if (section && section.nativeElement) {

        this.sectionPositions.push(section.nativeElement.offsetTop - 160);
      }
    });
  }

  scrollToSection(sectionId: number): void{

    if(this.sectionPositions.length == 0){
      this.calculateSectionPositions();
    }

    window.scrollTo({top: this.sectionPositions[Number(sectionId)], behavior: 'auto'});

    this.pauseFunctions = true;
    this.colorBar(Number(sectionId))

  }

  //Cores do menu de navegação superior
  colorBar(currentSectionIndex: number){

    this.navlinksTop.forEach((link: ElementRef) => {
      this.renderer.removeClass(link.nativeElement, 'active-nav-top');
    });

    this.barTop.forEach((bar: ElementRef) => {
      this.renderer.removeClass(bar.nativeElement, 'active-bar');
    });

    const navLink = this.navlinksTop.toArray()[currentSectionIndex];
    const bar = this.barTop.toArray()[currentSectionIndex];

    if (navLink && bar) {
      this.renderer.addClass(navLink.nativeElement, 'active-nav-top');
      this.renderer.addClass(bar.nativeElement, 'active-bar');
    }

    this.scrollToNavLink(currentSectionIndex);

  }

  /*Responsavel por dar o scroll no menu superior*/
  scrollToNavLink(index: number) {
    const navlinksTop = this.elementRef.nativeElement.querySelectorAll('.top-bar ul li span');
    const topbar = this.listBar.nativeElement as HTMLElement;
    if (topbar && navlinksTop[index]) {
      const navlinkRect = navlinksTop[index].getBoundingClientRect();
      const navlinkMiddleFromTopBarStart = navlinkRect.left - topbar.getBoundingClientRect().left + navlinkRect.width / 2;
      const containerMiddle = topbar.clientWidth / 2;
      const scrollPosition = navlinkMiddleFromTopBarStart - containerMiddle + topbar.scrollLeft;
      topbar.scrollLeft = scrollPosition;

    }

  }

  //Procura o index das categorias pela posição
  findCurrentSectionIndex(currentScrollY: number): number {
    if(this.sectionPositions.length != 0){
      for (let i = this.sectionPositions.length - 1; i >= 0; i--) {
        if (currentScrollY >= this.sectionPositions[i] - 85) {
          return i;
        }
      }
    }
    else{
      const sectionArray = this.sections.toArray();

      for (let i = sectionArray.length - 1; i >= 0; i--) {
        if (currentScrollY >= sectionArray[i].nativeElement.offsetTop - 85) {
          return i;
        }
      }
    }
    return 0;
  }

  alterationTopBar(): void{
    this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(window.innerWidth <= 846)
    {
      if(this.scrollTopPosition >= 140)
      {
        this.fixedbar = true
        this.topBar.nativeElement.classList.add('fixed-small')
        this.topBar.nativeElement.classList.remove('fixed-large')
        this.topBar.nativeElement.classList.remove('fixed-medium')
      }
      else if(this.scrollTopPosition <= 140){
        this.fixedbar = false
        this.topBar.nativeElement.classList.remove('fixed-small')
        this.topBar.nativeElement.classList.remove('fixed-large')
      }
    }
    else if(window.innerWidth >= 846)
    {
      this.topBar.nativeElement.classList.add('fixed-large')
      this.topBar.nativeElement.classList.remove('fixed-medium')
    }
  }

  private isScrolledToBottom(): boolean {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;

    return windowBottom >= docHeight;
  }

  // Ação a ser executada quando a orientação do dispositivo for alterada
  onOrientationChange(event: Event): void {
    // if (window.matchMedia("(orientation: portrait)").matches) {
    //   //vertical
    //   this.alterationTopBar();
    // } else {
    //   //horizontal
    //   this.alterationTopBar();
    // }
  }

  @HostListener('window:scroll', [])
  onScroll(): boolean  {
    clearTimeout(this.scrollTimeout);
    const scrollY = window.scrollY;


    if(!this.pauseFunctions)
    {
      const newSectionIndex: number = this.findCurrentSectionIndex(scrollY);

      if (newSectionIndex != this.currentSectionIndex) {
        this.currentSectionIndex = newSectionIndex;
        this.colorBar(newSectionIndex);
      }
      else{
        if (this.isScrolledToBottom()) {
          const number = this.sectionPositions.length - 1;
          this.colorBar(number)
          // console.log('Você atingiu o final da página!');
        }
      }
    }

    this.scrollTimeout = setTimeout(() => {
      this.handleScrollEnd();
    }, 100);

    this.alterationTopBar();

    return false;
  }

  private handleScrollEnd(): void {
    this.startTime = 0;
    setTimeout(() => {
      this.pauseFunctions = false;
    }, 100);

  }
}
