import { Injectable, OnInit } from '@angular/core';
import { Pedido, PedidoClass, additional, categoryAdd } from 'src/app/shared/Interfaces/pedido';
import { CacheService } from '../Cache/cache.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Produto } from 'src/app/shared/Interfaces/produtos';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnInit {
  product!: Pedido;

  idTableCardSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  idTableCard$: Observable<string> = this.idTableCardSubject.asObservable();

  productEditionSubject: BehaviorSubject<Pedido> = new BehaviorSubject<Pedido>(this.product);
  productEdition$: Observable<Pedido> = this.productEditionSubject.asObservable();

  private nameCustomerSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public nameCustomer$: Observable<string> = this.nameCustomerSubject.asObservable();

  indexItem!: number | undefined;

  private counterSubject = new BehaviorSubject<number>(0);
  public counter$ = this.counterSubject.asObservable();

  public cartItems: Pedido[] = [];

  constructor(private cacheService: CacheService){}
  ngOnInit(): void {
    this.getCartItems();
  }

  public updateCounter(newCounter: number){
    this.counterSubject.next(newCounter)
  }

  public updateNameCustomer(newName: string){
    this.nameCustomerSubject.next(newName);
  }

  addToCart(item: Pedido) {
    const matchingProducts = this.cartItems.filter(product => product.id_product === item.id_product);

    let index = this.indexItem

    if(index == null || index == undefined)
    {
      if (matchingProducts.length > 0) {
        
        //Procura se o produto adicionado tem uma replica identica já adicionada
        const matchingItem = matchingProducts.find(existingProduct => {

          if (item.observations !== '' && existingProduct.observations !== '') {

            return ( item.observations === existingProduct.observations && this.areAdditionalsEqual(item.categories, existingProduct.categories));

          } else if (item.observations === '' && existingProduct.observations === '') {

            return this.areAdditionalsEqual(item.categories, existingProduct.categories);

          }

          return false;
        });

        if (matchingItem) {
          matchingItem.quantity += item.quantity;
          
          //Se o produto for Pizza
          if(matchingItem.pizza == 1){

            matchingItem.totalPrice = matchingItem.totalPrice * matchingItem.quantity;

            if(matchingItem.pizzaPrice && matchingItem.pizzaPrice != 0){
              matchingItem.pizzaPrice = matchingItem.pizzaPrice * matchingItem.quantity;
            }

            if(matchingItem.flavorsValue && matchingItem.flavorsValue != 0 && matchingItem.baseFlavorsValue){
              matchingItem.flavorsValue = matchingItem.baseFlavorsValue * matchingItem.quantity;
            }
          }
          //Se o produto não for Pizza
          else{
            matchingItem.totalPrice = matchingItem.baseValue * matchingItem.quantity;      
          }

        } else {
          this.cartItems.push(item);
        }
      } else {
        this.cartItems.push(item);
      }
    }
    else
    {
      this.cartItems[index] = item;
      this.indexItem = undefined
    }

    this.updateCounter(this.cartItems.length)
    this.cacheService.setItemLocal("product-cart", this.cartItems);
  }


  areAdditionalsEqual(additionals1: categoryAdd[], additionals2: categoryAdd[]): boolean {
    // Se o comprimento dos arrays for diferente, eles não são iguais
    if (additionals1.length !== additionals2.length) {
      return false;
    }

    // Verificar se todos os ids de additionals1 existem em additionals2 e vice-versa
    for (const category1 of additionals1) {
      const matchingCategory = additionals2.find(category2 => category2.id_category === category1.id_category);

      if (!matchingCategory) {
        return false;
      }

      if (!this.areAdditionalArraysEqual(category1.additional, matchingCategory.additional)) {
        return false;
      }
    }

    return true;
  }

  private areAdditionalArraysEqual(additionals1: additional[], additionals2: additional[]): boolean {
    for (const additional1 of additionals1) {
      if (!additionals2.some(additional2 => additional2.add_id_product === additional1.add_id_product)) {
        return false;
      }
    }

    for (const additional2 of additionals2) {
      if (!additionals1.some(additional1 => additional1.add_id_product === additional2.add_id_product)) {
        return false;
      }
    }

    return true;
  }


  removeToCart(i: number){
    this.cartItems.splice(i, 1)
    this.cacheService.setItemLocal("product-cart", this.cartItems)
    this.updateCounter(this.cartItems.length)
  }

  //Edita o item (Deprecated)
  editItemCart(item: Pedido, index: number){
    this.cartItems[index] = item
    this.cacheService.setItemLocal("product-cart", this.cartItems)
    this.updateCounter(this.cartItems.length)
  }

  //Resgata os itens do cardápio, salvos em cache
  getCartItems() {
    this.cartItems = this.cacheService.getItemLocal("product-cart")
    if(this.cartItems == null)
    {
      this.cartItems = []
    }
    this.updateCounter(this.cartItems.length)
    return this.cartItems;
  }

  //Limpa o carrinho
  clearCart() {
    this.cartItems = [];
    this.updateCounter(this.cartItems.length)
    this.cacheService.setItemLocal("product-cart", this.cartItems = [])
  }
}
