import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/app/shared/Interfaces/order';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() order!: Order;
  vibration = false;



  ngOnInit(): void {
      setInterval(() => {
        this.startVibration();
        setTimeout(() => this.stopVibration(), 400);
      }, 2000);
  }

  startVibration() {
    this.vibration = true;
  }

  stopVibration() {
    this.vibration = false;
  }
}
