import { Injectable } from '@angular/core';
import { Wellcome } from 'src/app/shared/Interfaces/produtos';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cacheKeyPrefix = '';
  verificationInicialization = false

  constructor() {

   }


  setItemLocalTimeout(key: string, data: any, timeoutInMinutes: number): void {
    const cacheKey = this.cacheKeyPrefix + key;

    const currentTime = new Date();

    const expirationTime = new Date(currentTime.getTime() + timeoutInMinutes * 60 * 1000);

    const expirationTimeString = expirationTime.toLocaleString('pt-BR');

    const cacheData = { data, expirationTime: expirationTimeString };
    localStorage.setItem(cacheKey, JSON.stringify(cacheData));
  }

  getItemLocalTimeout(key: string): any {
    const cacheDataString = localStorage.getItem(key);

    if (!cacheDataString) {
      return null;
    }
    const cacheData = JSON.parse(cacheDataString);

    const currentTime = new Date();
    console.log('Horario de expiração',cacheData.expirationTime)
    if (currentTime.toLocaleString('pt-BR') > cacheData.expirationTime) {
      this.clearCacheTimeout(key);
      return null;
    }

    return cacheData.data;
  }

  clearCacheTimeout(key: string): void {
    localStorage.removeItem(key);
  }


  setItemLocal(key: string, value: any): void {
    const cacheKey = this.cacheKeyPrefix + key;
    localStorage.setItem(cacheKey, JSON.stringify(value));
  }
  setItemSession(key: string, value: any): void {
    const cacheKey = this.cacheKeyPrefix + key;
    sessionStorage.setItem(cacheKey, JSON.stringify(value));
  }

  getItemLocal(key: string): any {
    const cacheKey = this.cacheKeyPrefix + key;
    const cachedItem = localStorage.getItem(cacheKey);
    return cachedItem ? JSON.parse(cachedItem) : null;
  }
  getItemSession(key: string): any {
    const cacheKey = this.cacheKeyPrefix + key;
    const cachedItem = sessionStorage.getItem(cacheKey);
    return cachedItem ? JSON.parse(cachedItem) : null;
  }

  removeItemLocal(key: string): void {
    const cacheKey = this.cacheKeyPrefix + key;
    localStorage.removeItem(cacheKey);
  }
  removeItemSession(key: string): void {
    const cacheKey = this.cacheKeyPrefix + key;
    sessionStorage.removeItem(cacheKey);
  }

  clearCache(): void {
    // Limpa todos os itens do cache relacionados à sua aplicação (opcional).
    Object.keys(sessionStorage).forEach(key => {
      if (key.startsWith(this.cacheKeyPrefix)) {
        localStorage.removeItem(key);
      }
    });
  }

  // private urls: { [domain: string]: string } = {};

  // // Adicione esta função para armazenar a URL com base no domínio
  // storeUrlByDomain(domain: string, url: string): void {
  //   this.urls[domain] = url;
  // }

  // // Adicione esta função para recuperar a URL com base no domínio
  // getUrlByDomain(domain: string): string | null {
  //   return this.urls[domain] || null;
  // }

  // // Adicione esta função para retornar todas as URLs armazenadas no cache
  // getAllUrls(): { [domain: string]: string } {
  //   return this.urls;
  // }


}
