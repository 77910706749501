import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit, AfterViewInit {

  //#region Variaveis de elemento HTML
    @ViewChild('splash') splash!: ElementRef;
    @ViewChild('img') img!: ElementRef;
    imgString: string = './assets/logo/Gif/animation-logo.gif';
  //#endregion

  //#region Variaveis Locais
    preloadedImage!: HTMLImageElement;
    pauseGif: boolean = false;
  //#endregion

  //#region Inicializadores
    constructor(private changeDetectorRef: ChangeDetectorRef, private renderer: Renderer2){
    }

    ngOnInit(){
      this.preloadedImage = new Image();
      this.preloadedImage.src = './assets/logo/Gif/animation-logo.gif';
    }
    ngAfterViewInit(){
    }
    ngOnDestroy(): void {
      this.imgString = ''
    }

    loadImgComplete(event: Event){
      const element = event.target as HTMLImageElement;
      this.renderer.addClass(element, 'complete')
      setTimeout(() => {
        this.pauseGif = true;
      }, 1800);
    }


    replaceWithStaticImage() {
      this.imgString = '../../../../assets/logo/logo-pedeaqui.png';
      this.changeDetectorRef.detectChanges();
    }
  //#endregion

  //#region Funções de estilo (Style)
  //#endregion

  //#region Funções de lógica (Logic)
  //#endregion











}
