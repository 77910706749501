import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, forkJoin, from, map, of, tap, throwError } from 'rxjs';
import { Produto } from 'src/app/shared/Interfaces/produtos';
import { CacheService } from '../Cache/cache.service';
import { ApiService } from '../API/api.service';
import { Categorias, Company, Inicialization, typeInterface } from 'src/app/shared/Interfaces/company';
import { environment } from 'src/environments/environment';
import { Fidelity } from 'src/app/shared/Interfaces/fidelity';
import { Cliente } from 'src/app/shared/Interfaces/customer';




@Injectable({
  providedIn: 'root'
})
export class ConnectionService  implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
    private apiService: ApiService
  ) {
  }
  /*Variavel usada apenas para inicialização do Behavior empresa*/
  Inicializador!: Company;
  user!: Cliente;
  fidelity!: Fidelity;
  typeEmpty: typeInterface = {
    type: 0,
    qrCodeNumber: '',
    numberParam: 0
  }

  private typeParamSubject: BehaviorSubject<typeInterface> = new BehaviorSubject<typeInterface>(this.typeEmpty);
  public typeParam$: Observable<typeInterface> = this.typeParamSubject.asObservable();


  private userSubject: BehaviorSubject<Cliente> = new BehaviorSubject<Cliente>(this.user);
  public user$: Observable<Cliente> = this.userSubject.asObservable();

  private fidelitySubject: BehaviorSubject<Fidelity> = new BehaviorSubject<Fidelity>(this.fidelity);
  public fidelity$: Observable<Fidelity> = this.fidelitySubject.asObservable();


  permissionRequestOrderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  statusCompanySubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  companySubject: BehaviorSubject<Company> = new BehaviorSubject<Company>(this.Inicializador);
  nameCompanySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  verificationRequestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reloadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  redirectSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  permissionRequestOrder$: Observable<boolean> = this.permissionRequestOrderSubject.asObservable();
  statusCompany$: Observable<number> = this.statusCompanySubject.asObservable();
  reload$: Observable<boolean> = this.reloadSubject.asObservable();
  nameCompany$: Observable<string> = this.nameCompanySubject.asObservable();
  redirect$: Observable<boolean> = this.redirectSubject.asObservable();
  verificationRequest$: Observable<boolean> = this.verificationRequestSubject.asObservable();
  company$: Observable<Company> = this.companySubject.asObservable();

  typeParam: string = '';

  StopLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  StopLoading$: Observable<boolean> = this.StopLoadingSubject.asObservable();

  tabela!: Categorias[];
  parametro!: string;
  imgLogo!: string;
  imgBackground!: string;
  verificationTable = ''

  //urlAPI = urlAPIProduction.apiUrl;
  urlAPI = environment.apiUrl;

  ngOnInit(): void {
  }

  public updateType(newtype: typeInterface){
    this.typeParamSubject.next(newtype);
  }

  public updateUser(newUser: Cliente){
    this.userSubject.next(newUser);
  }

  public updateFidelity(newFidelity: Fidelity){
    this.fidelitySubject.next(newFidelity);
  }

  Empresa(nameCompany: string, typeParam: number): Observable<Inicialization>{

    let cacheCompany = '';
    let url = `${this.urlAPI}`
    let headers;

    if(typeParam == 1 || typeParam == 2){
      url += `/products/company/${nameCompany}`;
      headers = new HttpHeaders().set('type', '1')
    }
    else{
      url += `/products/company/${nameCompany}`;
      headers = new HttpHeaders().set('type', '2')
    }

    if(this.cacheService.getItemLocal('nameCompany'))
    {
      cacheCompany = this.cacheService.getItemLocal('nameCompany')
    }

    if(nameCompany == "")
    {

      return this.httpClient.get<Inicialization>(url).pipe(
        tap(data =>
          {

            this.companySubject.next(data.response)
            this.statusCompanySubject.next(data.response.company.status)
            this.nameCompanySubject.next(cacheCompany)
            this.cacheService.setItemSession('Company', data.response);
            this.company$.subscribe((data) =>{
              this.tabela = data.categories
            })

          }),
        map(data => data),
      );

    }
    else if(nameCompany != "")
    {
      if(cacheCompany != null)
      {
        if(nameCompany != cacheCompany)
        {
          this.cacheService.removeItemLocal('product-cart')
          this.cacheService.removeItemLocal('table')
          this.cacheService.removeItemLocal('Address_Saved')
        }
      }

      return this.httpClient.get<Inicialization>(url, { headers }).pipe(
        tap(data =>
          {
            this.nameCompanySubject.next(nameCompany);
            this.companySubject.next(data.response)
            this.statusCompanySubject.next(data.response.company.status)
            this.cacheService.setItemLocal('nameCompany', nameCompany)
            this.cacheService.setItemSession('Company', data.response);
            this.company$.subscribe((data) =>{
              this.tabela = data.categories
            })

          }),
        map(data => data)
      );
    }
    else {

      //retorna erro e trava na pagina de splash esperando uma empresa no panametro da url
      console.log("Não foi possivel localizar empresa por favor insira uma empresa, error:", Error)
      return throwError("Erro empresa não encontrada")

    }

  }

  //Retorna o produto selecionado pelo usuario
  getProduct(id: number): Produto{
    let foundProduto: Produto | undefined;

    this.tabela.forEach(category => {
      category.products.forEach(produto => {
        if(produto.id_product == id)
        {
          console.log(produto)
          foundProduto = produto
        }
      });
    });
    if(!foundProduto)
    {
      throw new Error(`Produto com ID ${id} não encontrado.`);
    }
    return foundProduto
  }

  getUser(){
    const user = this.cacheService.getItemLocal('user_key')
    if(user){
      this.apiService.getUsuario(user.phone).subscribe(
        (data) =>{
          this.updateUser(data.response.customer)
          const userKey = {
            phone: data.response.customer.phone,
            id_customer: data.response.customer.id_customer,
            name: data.response.customer.name
          }
          this.cacheService.setItemLocal('user_key', userKey)
        },
        (error) =>{
          console.log(error);
          if(error.status == 404)
          {
            this.cacheService.removeItemLocal('user_key')
            this.cacheService.removeItemLocal('userConfirmation')
          }
        }
      )
    }
    else{

    }
  }

  getFidelity(id_customer: string, id_company: number){
    this.apiService.getFidelity(id_customer, id_company).subscribe(
      (data) =>{
        this.updateFidelity(data)
      },
      (error) =>{
        console.log(error);
      }
    )

  }

}
export { Company };

