<div class="box">
    <div class="box-document">
        <span class="title-document">Documento na nota</span>
        <span class="document" *ngIf="this.document.document != ''">{{ this.document.formatted }}</span>
        <span class="document" *ngIf="this.document.document == ''">Opcional</span>
    </div>
    <div class="buttons-actions">
        <button class="button" *ngIf="this.document.document != ''" (click)="openInfoDocument()">Alterar</button>
        <button class="button" *ngIf="this.document.document == ''" (click)="openInfoDocument()">Adicionar</button>
    </div>
</div>

<div class="box-off-canva" [ngClass]="{'open': this.openInfo}" (click)="this.openInfo = false; this.scrollService.enableScroll()"></div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" [ngClass]="{'show': this.openInfo}" style="height: fit-content; z-index: 120;" [ngStyle]="{'height': offCanvasHeight + 'px', 'z-index': 120}" #offCanva>
  <div class="header-info"tabindex="-1" [ngClass]="{'show': openInfo}"  (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="content-offCanva">
        <span class="title-offCanva">Documento na nota</span>
        <span class="info-offCanva"></span>
        <input formatation class="input-document" [ngClass]="{'is-valid': this.corretFormat == true, 'is-invalid': this.corretFormat == false}" [(ngModel)]="inputValue" (input)="formatarDocumento($event)" placeholder="CPF ou CNPJ" maxlength="18" #input/>
    </div>
    <div class="button-offCanva">
      <button class="button-confirm" [disabled]="this.corretFormat == false || this.corretFormat == undefined" (click)="this.submitDocument(1)">Confirmar</button>
      <button *ngIf="this.document.document != ''" (click)="this.submitDocument(2)">Remover</button>
      <button *ngIf="this.document.document == ''" (click)="this.cancelEdit()">Cancelar</button>
    </div>
  </div>
</div>