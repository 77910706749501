import { NgModule } from '@angular/core';
import { FormatationDirective } from '../../directives/formatation.directive';

const directves = [
  FormatationDirective
]


@NgModule({
  declarations: directves,
  imports: [
  ],
  exports: directves
})
export class DirectiveModule { }
