import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, take } from 'rxjs';
import { typeInterface } from 'src/app/shared/Interfaces/company';
import { Pedido, PedidoClass, additional, categoryAdd } from 'src/app/shared/Interfaces/pedido';
import { Produto, additionalProduct } from 'src/app/shared/Interfaces/produtos';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { ScrollService } from 'src/app/shared/services/Scroll/scroll.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent {
  //#region Variaveis de elementos HTML
    @ViewChildren('sectionsCategory') sectionsCategory!: QueryList<ElementRef>;
    @ViewChildren('categorie') categorie!: QueryList<ElementRef>;
  //#endregion

  //#region Variaveis de Estilo
    alterationBg = false;
    showSideBar = true;
    toggleTopNavegation = true;
    alterationTopNavegation = false;
    showbutton = true;
    isZoom = false;
    limit = false;
    desabilitButton = false;
  //#endregion

  //#region Variaveis Locais
    private typeParam!: typeInterface;
    private nameCompany: string = '';

    temporaryOrde!:Pedido
    id!: number;
    scrollTopPosition: number = 0;
    requestSucess = false;
    produto!: Produto;
    productOrder!: Produto;
    additionalOrder: categoryAdd[] = [];
    verificationLength: number[] = []
    totalValue: number = 0;
    counter: number = 1;
    textObservation: string = '';
    filter: string = '';
    countText: number = 0;
    pedido!: PedidoClass;
    valorBase = 0;
    company!: Company;
    sectionPositions: number[] = [];
    subCategoryEmpty: number[] = []
    collapseStates: boolean[] = [];
    baseFlavorsValue = 0;
  //#endregion

  //#region Inicializadores

    constructor(
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private router: Router,
      private cartService: CartService,
      private changeDetectorRef: ChangeDetectorRef,
      private connection: ConnectionService,
      private scrollService: ScrollService,
      private apiService: ApiService,
      private elementRef: ElementRef,
      @Inject(DOCUMENT) private document: Document,
      private renderer: Renderer2
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam;
        })
        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
        })
    }

    ngOnInit(){
      //Resgata o parametro id
      this.route.params.subscribe(params => {
        this.id = params['id_product'];
      })

      //Desabilita o o refresh ao arrastar para baixo
      this.disablePullToRefresh()

      //Altera o background
      this.alterationBG();

      //resgata o id do produto para edição e executa a função para carregar essas informações
      this.cartService.productEdition$.subscribe((newProduct) =>{
          this.getProductEdition(newProduct);
      })

      this.scrollService.scrollingSubject.next(true);
    }

    ngAfterViewInit(){
      //Inclui ou remove a sidebar
      this.toggleSidebar();

      //Altera o menu de navegação superior
      this.toggleNavegationTop();

      //Calcula a posição das categorias de adicionais
      setTimeout(() => {
        this.calculateSectionPositions()
      }, 1000);

      //Iniciar o component sempre no topo da pagina
      window.scrollTo({
        behavior: 'instant' as ScrollBehavior,
        top: 0,
        left: 0
      })

      setTimeout(() => {
        this.checkCategoryInit()
      }, 400);


    }

    ngOnDestroy() {
      this.notZoomImage()
    }

  //#endregion

  //#region Funções de estilo (Style)

    //Inclui ou remove a sidebar da tela
    toggleSidebar(){
      if(window.innerWidth < 1169){
        this.showSideBar = false
        this.showbutton = false;
        this.changeDetectorRef.detectChanges();
      }
      else {
        this.showbutton = true;
        this.showSideBar = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    //Altera o menu de navegação superior
    toggleNavegationTop(){
      if(window.innerWidth < 700)
      {
        this.toggleTopNavegation = false
        this.changeDetectorRef.detectChanges();
      }
      else if(window.innerWidth > 700)
      {
        this.toggleTopNavegation = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    //Abre e fecha as categorias de adicionais
    toggleCollapse(index: number):void{
      this.collapseStates[index] = !this.collapseStates[index];
    }

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    //inclui a barra superior do menu de navegação
    includeDivBar(){
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if(window.innerWidth <= 846)
      {
        if(this.scrollTopPosition >= 380)
        {
          this.alterationTopNavegation = true
        }
        else{
          this.alterationTopNavegation = false
        }
      }
      else{}
    }

    //Abre a Imagem expandida
    zoomImage(){
      this.isZoom = true;
      this.renderer.addClass(this.document.body, 'body-no-scroll')
    }

    //Fecha a imagem expandida
    notZoomImage(){
      this.isZoom = false;
      this.renderer.removeClass(this.document.body, 'body-no-scroll')
    }

    //Altera o background
    alterationBG(){
      if(window.innerWidth <= 700)
      {
        this.alterationBg = false
      }
      else{
        this.alterationBg = true
      }
      this.changeDetectorRef.detectChanges();
    }

    //Da o scroll até a categoria de adicionais
    scrollToCategory(categorieSelected: any, i: number){
      this.calculateSectionPositions()
      const category = this.additionalOrder.find(
        category => categorieSelected.id_category === category.id_category
      )

      let categoryLength = 0;

      category?.additional.forEach(additional =>{
        categoryLength += additional.quantitySub
      })
      if(category?.division == categoryLength){
        if(this.limit)
        {
          for (let index = 0; index < this.produto.categories.length; index++) {
            const category = this.produto.categories[index];
            if(index > i){
              if (category.flavorCategory === 0) {
                window.scrollTo({ top: this.sectionPositions[index], behavior: 'smooth' });

                break;
              }
              else{
                if(index == this.produto.categories.length)
                {
                  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                }
              }
            }
          }
        }
        else{
          window.scrollTo({top: this.sectionPositions[Number(i + 1)], behavior: 'smooth'});
        }
      }
      else{
        if(category?.flavorCategory != 0){
          if(this.limit)
          {
            
            for (let index = 0; index < this.produto.categories.length; index++) {
              const category = this.produto.categories[index];
              if(index > i){
                if (category.flavorCategory === 0) {
                  window.scrollTo({ top: this.sectionPositions[index], behavior: 'smooth' });
                  break;
              }
              }
            }
          }
        }

      }
    }

    //Checa a categoria e bloqueia se atingir numero maximo
    checkCategory(categorieSelected: any, i: number){

      const element = document.querySelectorAll('.box-collapse')

      // console.log(element)
      const category = this.additionalOrder.find(
        category => categorieSelected.id_category === category.id_category
      )

      let counter = 0
      if(category){
        category.additional.forEach(element =>{
          counter += element.quantitySub
        })
      }

      if(category){
        if(category?.division != 0)
        {
          if(counter == categorieSelected.division)
          {
            element[i].classList.add('desabled')

          }
          else{
            element[i].classList.remove('desabled');
          }
        }
        else{
          element[i].classList.remove('desabled');
        }
      }
      else{
        element[i].classList.remove('desabled');
      }
    }

    checkCategoryInit(){
      const element = document.querySelectorAll('.box-collapse')

      this.produto.categories.forEach((categories, index) => {
        let counter = 0
        categories.products.forEach(produto => {
          counter += produto.quantity
        });

        if(categories?.division != 0)
        {
          if(counter == categories.division)
          {
            element[index].classList.add('desabled')

          }
          else{
            element[index].classList.remove('desabled');
          }
        }
        else{
          element[index].classList.remove('desabled');
        }

        if(counter == categories.division){

        }
      });
    }

    //Calcula as posições das categorias dos adicionais
    calculateSectionPositions() {
      this.sectionPositions = []
      this.sectionsCategory.forEach(section => {
        if (section && section.nativeElement) {

          this.sectionPositions.push(section.nativeElement.offsetTop);
        }
      });
    }

    //Verifica se todas as categorias requeridas estão selecionadas para poder avançar
    confimationCategories(){
      if(this.produto.categories){
        const categoriasRequired = this.produto.categories.filter(category => category.required === 1)
        const categoriasRequiredOrder = this.additionalOrder.filter(category => category.required === 1)


        if(categoriasRequired.length != categoriasRequiredOrder.length){
          this.desabilitButton = true;
          if(this.produto.pizza == 1)
          {
            let counter = 0
            this.additionalOrder.forEach(categoria => {
              categoria.additional.forEach(adicional =>{
                if(categoria.flavorCategory == 1)
                {
                  counter = counter + adicional.quantitySub
                }
              })
            })
  
            if(counter < this.produto.maxDivision)
            {
              this.limit = false
            }
            else if(counter == this.produto.maxDivision)
            {
              this.limit = true;
            }
          }
        }
        else{
          if(this.produto.pizza == 1)
          {
            let counter = 0
            this.additionalOrder.forEach(categoria => {
              categoria.additional.forEach(adicional =>{
                if(categoria.flavorCategory == 1)
                {
                  counter = counter + adicional.quantitySub
                }
              })
            })
  
            if(counter < this.produto.maxDivision)
            {
              this.limit = false
              if(counter < 1)
              {
                this.desabilitButton = true;
              }
              else{
                this.desabilitButton = false;
              }
            }
            else if(counter == this.produto.maxDivision)
            {


              setTimeout(() => {
                const element = document.querySelectorAll('.box-collapse')

                this.produto.categories.forEach((category, index) => {
                  if(category.flavorCategory == 1){
                    element[index].classList.add('desabled')
                  }
                });
              }, 500);


              this.desabilitButton = false;
              this.limit = true;
  
            }
          }
          else{
            this.desabilitButton = false;
          }


        }
      }
    }

    //captura toda tecla clicado no text area e faz a contagem
    capturaTexArea(event: Event){
      this.textObservation = (event?.target as HTMLInputElement).value;
      this.countText = this.textObservation.length
    }

    //Remove um no contador
    removeOneInCounter(){
      this.counter = this.counter - 1;

      if(this.produto.pizza != 1)
      {
        this.totalValue = this.valorBase * this.counter
      }
      else{
        this.totalValue = this.totalValue * this.counter
        this.logicDivision()
      }

    }

    //adiciona um no contador
    addOneInCounter(){
      this.counter = this.counter + 1;
      if(this.produto.pizza != 1)
      {
        this.totalValue = this.valorBase * this.counter
      }
      else{
        this.totalValue = this.totalValue * this.counter
        this.logicDivision()
      }

    }

    @HostListener('window:resize', [])
    onResize(){
      this.alterationBG();
      //altera a sidebar escondendo ela ou abrindo-a
      this.toggleSidebar();

      this.toggleNavegationTop();
    }

    @HostListener('window:scroll', [])
    onScroll() {
      this.includeDivBar()
    }

  //#endregion

  //#region Funções de lógica (Logic)

    getProductEdition(newOrde: Pedido){
      if(newOrde){
        this.apiService.getProduto(this.id, this.typeParam.type).subscribe((data) =>{

          this.produto = data.Product
          this.requestSucess = true
  
          // console.log(this.produto)
  
          //prenche as categorias de adicionais da variavel produto, se o meu produto para edição tiver adicional ele substitui
          this.verifiCategoryAdd(newOrde)
  
          //carrega as variaveis com os valores que vieram do produto editado
          this.counter = newOrde.quantity
          this.produto.price = newOrde.unitPrice;
          this.additionalOrder = newOrde.categories
          this.totalValue = Number(this.produto.price)
          this.valorBase = Number(this.produto.price)
          this.textObservation = newOrde.observations
  
          //Verifica se o produto é pizza ou não, e encaminha para lógica necessaria
          this.verifiLogic();
  
          // console.log(this.produto)
  
          //Extrai as categorias de produto e deixa as categorias de productOrder vazias
          const { categories, ...productWithoutCategories } = this.produto;
  
          this.productOrder = {
            ...productWithoutCategories,
            categories: [],
          };
  
          this.confimationCategories();
  
        },
        (error) =>{
          this.requestSucess = false;
        }
        )
      }

    }

    //Verifica se o produto é pizza ou não, e encaminha para lógica necessaria
    verifiLogic(){
      if(this.produto.pizza == 1)
      {
        this.additionalOrder.forEach(categories =>{
          categories.additional.forEach(additional =>{
            additional.totalPriceSub = Number(additional.unitPriceSub) * Number(additional.quantitySub)
          })
        })

        if(this.company.company.pizzaDivision == 0){

          this.logicDivision()
        }
        else if(this.company.company.pizzaDivision == 1)
        {
          this.logicMaxValue()
        }
        else if(this.company.company.pizzaDivision == 2)
        {
          this.logicDivisionCeil()
        }
      }
      else{
        this.calcTotalPedido();
      }
    }

    //altera os adicionais do produto pelo que veio da edição
    verifiCategoryAdd(newOrde: Pedido){

      if (this.produto.categories && newOrde) {
        let counter = 0;
        this.verificationLength = []
        this.produto.categories.forEach(category => {
          let valueQuantity = 0
          category.products.forEach(additional1 => {

            const matchingCategory = newOrde.categories?.find(category =>
              category.additional.some(additional => additional.add_id_product === additional1.id_product)
            );

            additional1.quantity = matchingCategory ?
              matchingCategory.additional.find(additional => additional.add_id_product === additional1.id_product)?.quantitySub || 0
            : 0;
            
            if(category.flavorCategory == 1){
              counter += additional1.quantity
            }

            valueQuantity += additional1.quantity



          });
          this.verificationLength.push(valueQuantity)
        });

        if(counter < this.produto.maxDivision){
          this.limit = false;
        }
        else if(counter == this.produto.maxDivision){
          this.limit = true
          this.changeDetectorRef.detectChanges()
        }
      }

    }

    //Calcula o valor do produto com os adicionais se o produto não for pizza
    calcTotalPedido(){
      this.valorBase = 0;
      this.totalValue = 0;
      this.totalValue = this.produto.price
      this.additionalOrder.forEach(category => {
        category.additional.forEach(additionais => {
          this.totalValue = Number(this.totalValue) + Number(additionais.totalPriceSub)
          this.changeDetectorRef.detectChanges()
        });

      });
      this.valorBase = this.totalValue

      this.totalValue = this.totalValue * this.counter
    }

    //retorna a pagina anterior
    goback(): void{
      this.router.navigate([this.nameCompany, 'carrinho']);
    }

    //#region Lógica para adicionar e remover adicionais

    //remove mais um ao adicional e monta a lógica para para o valor total
    removeOneInCounterAdd(adicional: additionalProduct, categorieSelected: any, i: number){
      //Verifica se o adicional ja é maior que zero para diminuir menos 1
      if (adicional.quantity > 0) {
        adicional.quantity--;
      }

      //Busca o indice da categoria na minha lista temporaria se não achar ele vai retornar -1
      const indexCategoria = this.additionalOrder.findIndex(item => item.id_category === categorieSelected.id_category);


      //Verifica se a categoria existe na lista mesmo
      if (indexCategoria !== -1) {
        const categoria = this.additionalOrder[indexCategoria];

        //Busca o indice do adicional na lista temporaria se não achar ele vai retornar -1
        const indexAdicional = categoria.additional.findIndex(item => item.add_id_product == adicional.id_product);

        if (indexAdicional !== -1) {

          const additional = this.additionalOrder[indexCategoria].additional[indexAdicional];

          //Com o adicinal encontrado verifica se a quantidade dele é maior que um, se for ele diminui um se não ele exclui o adicional da lista
          if(additional.quantitySub > 1)
          {
                this.additionalOrder[indexCategoria].additional[indexAdicional].quantitySub -= 1
                this.additionalOrder[indexCategoria].additional[indexAdicional].totalPriceSub -= this.additionalOrder[indexCategoria].additional[indexAdicional].unitPriceSub
          }
          else{
            this.additionalOrder[indexCategoria].additional.splice(indexAdicional, 1);
          }

        }

        //Verifica se a categoria que foi removido um adicional ainda contem algum adicional dentro dela
        if(categoria.additional.length == 0)
        {
          this.additionalOrder.splice(indexCategoria, 1)
        }

        //Verifica se o produto é uma pizza e manda para logica de acordo com a configuração da loja
        if(this.produto.pizza == 1)
        {
          if(this.additionalOrder.length != 0)
          {
            //Média
            if(this.company.company.pizzaDivision  == 0)
            {
              this.logicDivision();
            }
            //Maior Valor
            else if(this.company.company.pizzaDivision  == 1)
            {
              this.logicMaxValue();
            }
            //Média Ponderada
            else if(this.company.company.pizzaDivision  == 2)
            {
              this.logicDivisionCeil();
            }
          }
          else{
            this.totalValue = 0;
          }
        }
        else{
          this.calcTotalPedido()
        }
      }

      //Funções de alteração de visual
      this.confimationCategories();
      this.checkCategory(categorieSelected, i)
    }

    //Adicona mais um ao adicional e monta a lógica para para o valor total
    addOneInCounterAdd(adicional: additionalProduct, categorieSelected: any, i: number){
      //Adiciona +1 na quantidade do adicional
      adicional.quantity++

      //Monta o objeto do adicional
      const item: additional = {
        add_id_product : adicional.id_product,
        nameSub: adicional.name,
        quantitySub: adicional.quantity,
        unitPriceSub: adicional.price,
        totalPriceSub: adicional.price * adicional.quantity,
        paymentAccept: adicional.paymentAccept,
        observationsSub: ''
      }

      //Verifica se a categoria que escolhi o adicional ja existe na minha lista temporaria
      const existingCategory = this.additionalOrder.find(
        category => categorieSelected.id_category === category.id_category
      )

      if(existingCategory){

        //Verfica se o adicional selecionado ja existe na lista temporaria
        const existingItem = existingCategory.additional.find(
          subItem => item.add_id_product === subItem.add_id_product
        )

        if(existingItem)
        {
          existingItem.quantitySub = item.quantitySub
          existingItem.totalPriceSub = item.totalPriceSub
          existingItem.observationsSub = item.observationsSub
        }
        else{
          existingCategory.additional.push(item)
        }
      }
      else{

        //Cria o objeto de categoria se a categoria não existir na lista temporaria
        const category: categoryAdd = {
          id_category: categorieSelected.id_category,
          categoryName: categorieSelected.categoryName,
          additional: [],
          division: categorieSelected.division,
          edgeCategory: categorieSelected.edgeCategory,
          flavorCategory: categorieSelected.flavorCategory,
          required: categorieSelected.required,
          categoryLength: categorieSelected.categoryLength
        }
        //Adiciona o item que foi selecionado a essa nova categoria, e adiciona essa categoria, a lista temporaria de adicionais
        category.additional.push(item)
        this.additionalOrder.push(category)
      }

      //Verifica se o produto é uma pizza
      if(this.produto.pizza == 1)
      {
        //Lógica que faz a verificação para qual tipo de configuração o estabelecimento usa

        //Média
        if(this.company.company.pizzaDivision  == 0)
        {
          this.logicDivision();
        }
        //Maior Valor
        else if(this.company.company.pizzaDivision  == 1)
        {
          this.logicMaxValue();
        }
        //Média Ponderada
        else if(this.company.company.pizzaDivision  == 2)
        {
          this.logicDivisionCeil();
        }

      }
      else{
        this.calcTotalPedido()
      }

      //Funções para alterar o visual apenas
      this.confimationCategories();
      this.scrollToCategory(categorieSelected, i)

      this.checkCategory(categorieSelected, i)
      this.changeDetectorRef.detectChanges()
    }

    //#endregion

    //#region Lógica para calcular a divisão do valor do sabor
    logicDivision(){
      let quantyFlavor = 0;
      let valueFlavor = 0;

      let quantyEdge = 0;
      let valueEdge = 0;

      //Valores provisorios
      let provisionalValuePizza = 0;
      let provisionalValueEdge = 0;
      let provisionalValueProduct = 0;

      //Percorre todo o array temporario de adicionais
      this.additionalOrder.forEach(categoria => {
        categoria.additional.forEach(adicional =>{
          //Verifica se a categoria que o for esta passando é um sabor
          if(categoria.flavorCategory == 1)
          {
            quantyFlavor += adicional.quantitySub;
            valueFlavor += adicional.totalPriceSub
          }
          else if(categoria.edgeCategory == 1)
          {
            quantyEdge += adicional.quantitySub;
            valueEdge += adicional.totalPriceSub
          }
          else{
            provisionalValueProduct += adicional.totalPriceSub
          }
        })

      })

      //Se o value o quantidadeTotal forem diferentes de 0 ele atribui essa divisão ao valor provisorio
      if(valueFlavor && quantyFlavor)
      {
        provisionalValuePizza = valueFlavor / quantyFlavor;
      }
      if(valueEdge && quantyEdge)
      {
        provisionalValueEdge = valueEdge / quantyEdge;
      }

      this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

      this.valorBase = this.totalValue

      //Verifica se a variavel counter esta maior que zero para multiblicar o valor total pelo numero da varivel
      if(this.counter != 0){
        this.totalValue = this.totalValue * this.counter
      }

    }
    //#endregion

    //#region Lógica para calcular o maior valor do sabor
    logicMaxValue(){

      //Valores provisorios
      let provisionalValuePizza = 0;
      let provisionalValueEdge = 0;
      let provisionalValueProduct = 0;

      //Percorre o Array de adicionais tempoararios
      this.additionalOrder.forEach(categoria => {
          categoria.additional.forEach(adicional =>{
            if(categoria.flavorCategory == 0 && categoria.edgeCategory == 0)
            {
              provisionalValueProduct += adicional.totalPriceSub
            }
            else if(categoria.edgeCategory == 1){
              //Calcula o valor do adicional dividido pela quantidade e se esse valor for maior do que esta registrado no valor provisorio, ele substitui
              if(adicional.quantitySub > 1){

                const value = adicional.totalPriceSub / adicional.quantitySub;

                if(value > provisionalValueEdge){

                  provisionalValueEdge = value;

                }
              }
              else{
                if(adicional.totalPriceSub > provisionalValueEdge)
                {
                  provisionalValueEdge = adicional.totalPriceSub
                }
              }
            }
            else if(categoria.flavorCategory == 1){
              //Calcula o valor do adicional dividido pela quantidade e se esse valor for maior do que esta registrado no valor provisorio, ele substitui
              if(adicional.quantitySub > 1)
              {
                const value = adicional.totalPriceSub / adicional.quantitySub

                if(value > provisionalValuePizza)
                {
                  provisionalValuePizza = value
                }
              }
              else{
                if(adicional.totalPriceSub > provisionalValuePizza)
                {
                  provisionalValuePizza = adicional.totalPriceSub
                }
              }

            }

          })

          //Total value recebe o valor das variaveis acima
          this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

          this.valorBase = this.totalValue

          if(this.counter != 0){
            this.totalValue = this.totalValue * this.counter
          }
      })

    }
    //#endregion

    //#region Lógica para calcular a divisão do valor do sabor Média Ponderada
      logicDivisionCeil(){
        let quantyFlavor = 0;
        let valueFlavor = 0;

        let quantyEdge = 0;
        let valueEdge = 0;

        //Valores provisorios
        let provisionalValuePizza = 0;
        let provisionalValueEdge = 0;
        let provisionalValueProduct = 0;

        //Percorre todo o array temporario de adicionais
        this.additionalOrder.forEach(categoria => {
          categoria.additional.forEach(adicional =>{
            //Verifica se a categoria que o for esta passando é um sabor
            if(categoria.flavorCategory == 1)
            {
              quantyFlavor += adicional.quantitySub;
              valueFlavor += adicional.totalPriceSub
            }
            else if(categoria.edgeCategory == 1)
            {
              quantyEdge += adicional.quantitySub;
              valueEdge += adicional.totalPriceSub
            }
            else{
              provisionalValueProduct += adicional.totalPriceSub
            }
          })

        })

        //Se o value o quantidadeTotal forem diferentes de 0 ele atribui essa divisão ao valor provisorio
        if(valueFlavor && quantyFlavor)
          {
            provisionalValuePizza = valueFlavor / quantyFlavor;
            provisionalValuePizza = Math.ceil(provisionalValuePizza * 10) / 10;
          }
          if(valueEdge && quantyEdge)
          {
            provisionalValueEdge = valueEdge / quantyEdge;

            provisionalValueEdge = Number(provisionalValueEdge.toFixed(2));

            provisionalValueEdge = Math.floor(provisionalValueEdge * 10) / 10;
          }

        this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

        this.valorBase = this.totalValue

        //Verifica se a variavel counter esta maior que zero para multiblicar o valor total pelo numero da varivel
        if(this.counter != 0){
          this.totalValue = this.totalValue * this.counter
        }

      }
    //#endregion

    //#region Montar o pedido e passar para o carrinho

    //Responsavel por montar o objeto do pedido
    Pedido(){
      this.totalValue
      let quantityFlavor = 0;
      let quantityEdge = 0;
      let valueFlavor = 0;
      let valueEdge = 0;
      let pizzaPrice = 0;
      let flavorsValue = 0;
      let baseFlavorsValue = 0;

      if(this.productOrder.pizza == 1)
      {
        //Percorre a lista, Verifica se uma categoria é sabor e resgata o valor e as quantidades
        this.additionalOrder.forEach(categoria => {
          if(categoria.flavorCategory == 1)
          {
            categoria.additional.forEach(adicional =>{
              quantityFlavor += adicional.quantitySub;
              valueFlavor += adicional.totalPriceSub
            })
            categoria.categoryLength = quantityFlavor
          }
          else if(categoria.edgeCategory == 1)
          {
            categoria.additional.forEach(adicional =>{
              quantityEdge += adicional.quantitySub;
              valueEdge += adicional.totalPriceSub
            })
            categoria.categoryLength = quantityEdge
          }
          
        })

        //Faz a divisão
        let divisionFlavor = valueFlavor / quantityFlavor
        let valueDivisionFlavor = divisionFlavor / quantityFlavor

        if(divisionFlavor && valueDivisionFlavor){
          if(this.company.company.pizzaDivision  == 2)
          {
            pizzaPrice = Math.ceil(divisionFlavor)
          }
          else{
            pizzaPrice = divisionFlavor
          }

        }


        let divisionEdge = valueEdge / quantityEdge
        let valueDivisionEdge = divisionEdge / quantityEdge


        //Percorre a lista novamente e adicona ao valores dos adicionais o valor dividido
        this.additionalOrder.forEach(categoria => {
          if(categoria.flavorCategory == 1)
          {
            categoria.additional.forEach(adicional =>{
              if(this.company.company.pizzaDivision  == 2)
              {
                adicional.totalPriceSub  = Number(valueDivisionFlavor)
              }
              else{
                adicional.totalPriceSub  = Number(valueDivisionFlavor.toFixed(2))
              }
              
            })
          }
          else if(categoria.edgeCategory == 1){
            categoria.additional.forEach(adicional =>{
              if(this.company.company.pizzaDivision  == 2)
              {
                adicional.totalPriceSub  = Number(valueDivisionEdge)
              }
              else{
                adicional.totalPriceSub  = Number(valueDivisionEdge.toFixed(2))
              }

            })
          }

        })

        let additionalsValue = 0;

        this.additionalOrder.forEach(categoria => {

          if(categoria.flavorCategory == 0)
          {
            categoria.additional.forEach(additional =>{
              additionalsValue += additional.totalPriceSub
            })
          }
          
        })

        additionalsValue = additionalsValue * this.counter

        if(additionalsValue != 0)
        {
          flavorsValue = this.totalValue - additionalsValue / this.counter;
        }
        else{
          flavorsValue = this.totalValue / this.counter;
        }

        if(this.counter > 1){
          this.baseFlavorsValue = flavorsValue / this.counter
        }
        else{
          this.baseFlavorsValue = flavorsValue
        }
      }
      //Envia as informações para a Classe
      this.pedido = new PedidoClass(this.productOrder, this.counter, this.textObservation, this.additionalOrder, this.valorBase, this.totalValue, pizzaPrice, flavorsValue, this.baseFlavorsValue)
    }

    //Adiciona o pedido e suas caracteristicas ao seu carrinho
    addProduct(){
      this.renderer.removeClass(this.document.body, 'body-no-scroll');
      this.Pedido()
      this.cartService.addToCart(this.pedido)
      this.toastr.success(`${this.produto.name} editado com sucesso!`, 'Successo!', { positionClass: 'toast-top-right', timeOut: 1500});
      this.cartService.productEditionSubject.next(this.temporaryOrde)
      // console.log(this.pedido)
    }

    //Se o usuario escolher continuar comprando ele removera a possibilidade de não fazer scroll, e adicionara o produto no carrinho
    continuarCompra(){
      this.renderer.removeClass(this.document.body, 'body-no-scroll');
      this.addProduct();
      this.router.navigate([this.nameCompany, 'carrinho']);
    }

    //#endregion

    //Função responsavel por fazer o filtro de pesquisa
    updatefilter(event: Event) {
      this.filter = (event?.target as HTMLInputElement).value.toLowerCase();
      this.counterLengthSub();

    }
    //Calcula se as subcategorias tem produtos visiveis
    counterLengthSub(){
      this.subCategoryEmpty = []
      this.produto.categories.forEach(category => {

        const visibleElementsCount = category.products.reduce((count, adicional) => {
          return adicional.name.toLowerCase().includes(this.filter) || this.filter == '' ? count + 1 : count;
        }, 0);
        
        this.subCategoryEmpty.push(visibleElementsCount);
      });

      // console.log(this.subCategoryEmpty)
    }

  //#endregion
}
